import React from "react";
import { isEmpty } from "@/src/utils";
import "../../../../styles/input/input.scss";
import { Input } from "antd";

const InputComponent = (props) => {
  const {
    placeholder,
    classNames,
    type,
    addonAfter,
    suffixIcon,
    maxLength,
    name,
    error,
    value,
    inputRef,
    handleChange,
    handleBlur,
    ariaLabel,
    id,
    disabled,
    handleKeyDown,
    minLength,
    handleFocus,
    handleClick,
    isCount,
    addonBefore,
    errorClass,
    showCount,
  } = props;
  return (
    <React.Fragment>
      <Input
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        className={`${classNames} common_input_box`}
        addonAfter={addonAfter}
        addonBefore={addonBefore}
        suffix={suffixIcon}
        maxLength={maxLength}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        ref={inputRef}
        disabled={disabled}
        aria-label={ariaLabel}
        id={id}
        minLength={minLength}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        autoComplete="off"
        showCount={showCount || false}
      />
      <div className={"error-length-message-main"}>
        {!isEmpty(error) && (
          <p className={`error-message ${errorClass}`}>{error}</p>
        )}
        {isCount && name !== "websiteUrl" && (
          <p className={"tag-length-main"}>
            {isEmpty(value) || value?.length === 0
              ? maxLength
              : maxLength - value?.length}
          </p>
        )}
      </div>
    </React.Fragment>
  );
};

export default InputComponent;
