export const TOKEN =
  typeof window !== "undefined" && localStorage?.getItem("authToken");
export const AUTH_TOKEN = "authToken";
export const ALPHABET_REGEX = /\D/g;
export const SPACE_REGEX = /\s/g;
export const CHARACTER_REGEX = /^[a-zA-Z\s]+$/g;
export const NUMBER_REGEX = /[^A-Za-z ]/gi;
export const USERNAME_REGEX = /[^a-z0-9._-]/gi;
export const LINK_REGEX =
  /^(?:(?:https?|ftp):\/\/)(?:(?:[a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,6}))(?:\/(?:[a-zA-Z0-9-.!~*'();\/?:@&=+$,#])*){0,}$/;
export const EMAIL_REGEX = /^[A-Za-z0-9._-]+@[A-Za-z]+\.[A-Za-z]{2,}$/;
export const PASSWORD_REGEX =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{8,}$/;

export const HUNDRED_COUNT = 100;
export const TWELVE_COUNT = 12;
export const FIFTEEN_COUNT = 15;
export const THIRTY_COUNT = 30;

export const FN_REQUIRED = "First name is required";
export const LN_REQUIRED = "Last name is required";
export const PHONE_REQUIRED = "Phone number is required";
export const COUNTRY_REQUIRED = "Country is required";
export const CATEGORY_REQUIRED = "Category is required";
export const STYLE_REQUIRED = "Styles  is required";
export const TITLE_REQUIRED = "Title is required";
export const FONTS_REQUIRED = "Fonts is required";
export const WEBSITE_REQUIRED = "Website URL is required";
export const AGENCY_NAME_REQUIRED = "Agency name is required";
export const NAME_LENGTH = "Only allow 25 character";
export const NUM_LENGTH = "Number must be  10 digits";
export const VALID_WEBSITE =
  "Enter valid URL (https://www.google.com or https://www.india.gov.in)";
export const VALID_PERSONAL_WEBSITE = "Enter valid URL";
export const USERNAME_VALID = "Enter valid username";
export const WEB_TITLE_REQUIRED = "Website title is required";
export const COLLECTION_REQUIRED = "Collection name is required";
export const USERNAME_REQUIRED = "User Name required";
export const DESCRIPTION_REQUIRED = "Description is required";
export const DESIGN_REQUIRED = "Design type  is required";
export const TIME_REQUIRED = "Time is required";
export const BUDGET_REQUIRED = "Budget is required";
export const BIO_REQUIRED = "Bio is required";
export const PORTFOLIO_TITLE_REQUIRED = "Portfolio title is required";
export const PROJECT_DESCRIPTION_REQUIRED = "Project Description  is required";
export const ONLY_NUM = "Only number allow";
export const ONLY_CHAR = "Only character allow";
export const AGENCY_NAME_LENGTH = "Only allow 30 character";
export const WEBSITE_TITLE_LENGTH = "Website title must be 80 character";
export const COLLECTION_LENGTH = "Collection name must be 25 character";
export const USERNAME_LENGTH = "UserName must be 30 character";
export const DESCRIPTION_LENGTH = "Description must be 200 character";
export const PROJECT_DESCRIPTION_LENGTH =
  "Project Description must be 2500 character";
export const DESIGN_LENGTH = "Design must be 80 character";
export const EMAIL_REQUIRED = "Email is required";
export const VALID_EMAIL = "Invalid email";
export const PASSWORD_REQUIRED = "Password is required";
export const CURRENT_PASSWORD_REQUIRED = "Current Password is required";
export const PASSWORD_LENGTH = "Password must be 8 character";
export const MAX_LENGTH_CATEGORY = "You can select only 5 category";
export const MIN_LENGTH_CATEGORY = "Select atLeast 3 category";
export const MAX_LENGTH_STYLE = "You can select only 5 style";
export const MIN_LENGTH_STYLE = "Select atLeast 3 style";
export const MAX_LENGTH_FONTS = "You can select only 5 style";
export const MIN_LENGTH_FONTS = "Select atLeast 2 font style";

export const VALID_PASSWORD =
  "Password contain must be one uppercase , one lowercase , one number and one special character";
export const CONFIRM_PASSWORD_REQUIRED = "Confirm password is required";
export const CONFIRM_PASSWORD_MATCH =
  "Confirm password must be match with password";
export const RESET_PASSWORD_MATCH =
  "Current password can not match with new password";
