"use client";
import { Modal } from "antd";
import React, { useEffect } from "react";
import "../../../../styles/successmodal/successmodal.scss";
import {
  emailSendImage,
  successImage,
  warningImage,
} from "../../../../public/assets";
import dynamic from "next/dynamic";
const Lottie = dynamic(() => import("react-lottie-player"));

const SuccessModal = ({
  isSuccessModal,
  setIsSuccessModal,
  descriptionText,
  isWarning,
  isEmailSend,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSuccessModal(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <React.Fragment>
      <Modal
        open={isSuccessModal}
        closeIcon={false}
        footer={false}
        className={"success-modal-main"}
        centered
        width={460}
      >
        {isWarning ? (
          <Lottie
            animationData={warningImage}
            className={"success-image-main"}
            play
          />
        ) : isEmailSend ? (
          <Lottie
            animationData={emailSendImage}
            className={"success-image-main"}
            play
          />
        ) : (
          <Lottie
            animationData={successImage}
            className={"success-image-main"}
            play
          />
        )}
        <h1 className={"success-image-text"}> {descriptionText}</h1>
      </Modal>
    </React.Fragment>
  );
};

export default SuccessModal;
