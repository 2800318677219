"use client";
import React from "react";
import { CardComponent, NoDataFound } from "..";
import { isEmpty } from "@/src/utils";
import Slider from "@ant-design/react-slick";
import { Skeleton } from "antd";

const WebsiteCardCarouselView = ({
  slidesToShowImage,
  websiteCardData,
  isScreenShotOpen,
  token,
  modal,
  setModal,
  setWebsiteCardData,
  handleDrawerOpen,
  manageCollection,
  drawer,
  setDrawer,
  handleSaveCollection,
  handleAddCollectionName,
  isLoadingTopWebsite,
  handleWebsiteViewCount,
  scrollToTop,
  handleOnMouseHover,
  userOnHoverFilterData,
  userNameApplied,
  isCarousel,
  handleOnMouseLeave,
  isPortfolioWebsite,
}) => {
  const options = {
    dots: false,
    autoplay: true,
    centerMode:
      websiteCardData?.length <= 2 ? false : isScreenShotOpen ? true : true,
    centerPadding: "60px",
    speed: 4000,
    autoplaySpeed: 3000,
    slidesToShow: slidesToShowImage,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: isScreenShotOpen ? 1 : 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: isScreenShotOpen ? 1 : 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: isScreenShotOpen ? 1 : 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };
  return (
    <React.Fragment>
      {!isEmpty(websiteCardData) && Array.isArray(websiteCardData) ? (
        <Slider {...options}>
          {websiteCardData?.map((cardCarouselData) => {
            return (
              !isEmpty(cardCarouselData?.screenShotImg) && (
                <div key={cardCarouselData?.websiteId} onClick={scrollToTop}>
                  <CardComponent
                    {...{
                      websiteDataObj: cardCarouselData,
                      isScreenShotOpen,
                      token,
                      data: websiteCardData,
                      modal,
                      setModal,
                      setWebsiteCardData,
                      handleDrawerOpen,
                      manageCollection,
                      drawer,
                      setDrawer,
                      handleSaveCollection,
                      handleAddCollectionName,
                      handleWebsiteViewCount,
                      handleOnMouseHover,
                      userOnHoverFilterData,
                      userNameApplied,
                      isCarousel,
                      handleOnMouseLeave,
                      isPortfolioWebsite,
                    }}
                  />
                </div>
              )
            );
          })}
        </Slider>
      ) : isLoadingTopWebsite ? (
        <div className={"top_skeleton_main"}>
          {new Array(slidesToShowImage).fill(null).map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i} className={"top_skeleton"}>
              <Skeleton.Input active />
            </div>
          ))}
        </div>
      ) : (
        isEmpty(websiteCardData) &&
        !isLoadingTopWebsite && <NoDataFound {...{ isCarousel: true }} />
      )}
    </React.Fragment>
  );
};

export default WebsiteCardCarouselView;
