"use client";
import React from "react";
import { Col, Row } from "antd";
import "../../../../styles/drawer/drawer.scss";
import Image from "next/image";
import { InputComponent } from "..";
import { closes } from "../../../../public/assets";
import { isEmpty } from "@/src/utils";
import { SwatchesPicker } from "react-color";

const DrawerComponent = (props) => {
  const {
    filterValueJson,
    onClose,
    open,
    data,
    defaultClose,
    handleFilterData,
    handleDropDownSearchValue,
    dropdownSearchValue,
    handleCloseFilterData,
    getRegionNames,
    handleFilterChange,
  } = props;

  return (
    <div className={"drawer_main"}>
      <div
        onClose={onClose}
        className={`${
          open
            ? `${"drawer_open"} `
            : !defaultClose
              ? "default_drawer_close"
              : "drawer_close"
        } ${"drawer_wrap"}`}
      >
        <div>
          <div className={"menu-modal-search-bar-main"}>
            <InputComponent
              value={dropdownSearchValue}
              type="search"
              placeholder="search..."
              handleChange={(e) => handleDropDownSearchValue(e)}
              classNames={"modal-search-bar"}
            />
          </div>
          <div className={"filter_drawer_data_wrap"}>
            {data?.map((obj) => (
              <div key={obj?.title}>
                <h1 className={"modal_option_title"}>{obj?.title}</h1>
                {isEmpty(obj?.data) && obj?.title !== "Color" ? (
                  <p className={"no_data_found_text"}>No Data Found</p>
                ) : obj?.title === "Color" ? (
                  <React.Fragment>
                    <SwatchesPicker
                      className={"color_picker_main"}
                      onChange={(e) => handleFilterChange(e?.hex, obj?.title)}
                    />
                  </React.Fragment>
                ) : (
                  <Row gutter={[15, 15]} className={"modal_list_main"}>
                    {obj?.data?.map((ele) => {
                      const title = obj?.title?.toLowerCase();
                      return (
                        <Col
                          key={ele?.name}
                          span={24}
                          xxl={8}
                          xl={8}
                          lg={8}
                          md={12}
                          sm={24}
                          xs={24}
                          className={"filter_nav_item_main"}
                        >
                          <div
                            className={`${
                              filterValueJson[title] === ele.name
                                ? "filter_nav_item_selected"
                                : ""
                            } ${"filter_nav_item"}`}
                          >
                            <p
                              className={"options_lists"}
                              onClick={() =>
                                handleFilterData(obj?.title, ele?.name)
                              }
                            >
                              {obj?.title === "Country"
                                ? getRegionNames.of(ele?.name)
                                : ele?.name}
                            </p>
                            {filterValueJson[title] === ele.name && (
                              <Image
                                src={closes}
                                alt="close"
                                onClick={() =>
                                  handleCloseFilterData(obj?.title)
                                }
                                className={"filter_nav_icon"}
                              />
                            )}
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrawerComponent;
