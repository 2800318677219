"use client";
import React, { useEffect, useRef, useState } from "react";
import AuthenticationView from "./AuthView";
import { SPACE_REGEX, USERNAME_REGEX } from "@/src/const/primitive";
import { isEmpty } from "@/src/utils";
import {
  loginUser,
  loginWithGoogle,
  signUpUser,
  userNameAlreadyExists,
} from "@/src/redux/actions";
import { useDispatch } from "react-redux";
import { useNav } from "@/src/components/CommonComponents/context/NavbarProvider";
import { useGoogleLogin } from "@react-oauth/google";
import { addWebsiteValidation } from "@/src/utils/validation/validation";
import { useRouter } from "next/navigation";

const AuthenticationContainer = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [isWarningModal, setIsWarningModal] = useState(false);
  const [isEmailSendModal, setIsEmailSendModal] = useState(false);
  const [isPasswordShow, setIsPasswordShow] = useState({});
  const [isStatus, setIsStatus] = useState(false);
  const [coords, setCoords] = useState({ latitude: "", longitude: "" });
  const dispatch = useDispatch();
  const { isAuthModal, setIsAuthModal } = useNav();

  const isSignUpErr = {
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };
  const isLogInErr = {
    email: "",
    password: "",
  };
  const [authDetails, setAuthDetails] = useState(
    isSignUp ? isSignUpErr : isLogInErr
  );
  const [loginError, setLoginError] = useState({
    auth: "",
  });
  const inputRef = useRef();
  const router = useRouter();

  const handleClickSignIn = () => {
    let signUp = !isSignUp;
    setIsSignUp(signUp);
    setAuthDetails(signUp ? isSignUpErr : isLogInErr);
    setLoginError({});
  };
  useEffect(() => {
    setAuthDetails(isSignUp ? isSignUpErr : isLogInErr);
  }, [isSignUp]);
  const handleInputChange = (e, FIRST_INDEX, LAST_INDEX) => {
    let { name, value } = e.target;
    value = value.trimStart();
    value = value.replace(
      name === "userName" ? USERNAME_REGEX : SPACE_REGEX,
      ""
    );
    const loginDetails = {
      ...authDetails,
      [name]: value?.slice(FIRST_INDEX, LAST_INDEX),
    };
    value?.length < LAST_INDEX && setAuthDetails(loginDetails);
  };
  const handleUserNameAlreadyExists = async () => {
    let payload = {
      userName: authDetails?.userName?.toLowerCase(),
    };
    const data = await dispatch(userNameAlreadyExists(payload));
    if (data?.exists) {
      setLoginError({
        ...loginError,
        userName: "User Name already exists",
      });
    } else {
      setLoginError({
        ...loginError,
        userName: "",
      });
    }
  };
  const handleAuthBlur = (maxLength, name) => {
    const { errors } = addWebsiteValidation(
      name,
      authDetails,
      loginError,
      isSignUp
    );
    setLoginError(errors);
    if (name === "userName") {
      handleUserNameAlreadyExists();
      return;
    }
    if (authDetails[name]?.length === maxLength) {
      setLoginError({
        ...loginError,
        [name]: "",
      });
    } else {
      setLoginError({
        ...errors,
        auth: "",
      });
    }
  };
  const handleCancelAuthModal = () => {
    setIsStatus(false);
    setIsSignUp(false);
    setIsAuthModal(false);
    setIsPasswordShow({});
    setAuthDetails(isSignUp ? isSignUpErr : isLogInErr);
    setLoginError({});
  };
  const handleForgotPassword = (e) => {
    e.preventDefault();
    setIsAuthModal(false);
    router.push("/forgot-password");
  };
  const handleGoogleLoginSuccess = async (tokenResponse) => {
    const accessToken = tokenResponse.access_token;
    const { status } = await dispatch(loginWithGoogle(accessToken, coords));
    if (status === 200) {
      const timer = setTimeout(() => {
        setIsAuthModal(false);
        window.location.reload();
      }, 2000);
      return () => clearTimeout(timer);
    }
  };
  const handleGoogleLogin = useGoogleLogin({
    onSuccess: handleGoogleLoginSuccess,
  });
  const handleSubmitLoginData = async (e) => {
    e.preventDefault();
    let inputName = e?.target?.name;
    let status;
    let names;
    if (!isEmpty(inputRef.current)) {
      if (
        isEmpty(authDetails?.email) ||
        isEmpty(authDetails?.password) ||
        isEmpty(authDetails?.confirmPassword) ||
        isEmpty(authDetails?.userName) ||
        !isEmpty(loginError?.email) ||
        !isEmpty(loginError?.password) ||
        !isEmpty(loginError?.confirmPassword) ||
        !isEmpty(loginError?.userName)
      ) {
        inputRef.current.focus();
      }
    } else {
      let isValidArr = Object.keys(authDetails);
      isValidArr?.forEach((val) => {
        names = val;
      });
      const { errors } = addWebsiteValidation(
        inputName ? inputName : names,
        authDetails,
        loginError,
        isSignUp
      );
      setLoginError(errors);
      if (Object.values(errors).every((value) => !value)) {
        setIsStatus(true);
        if (isSignUp) {
          status = await dispatch(
            signUpUser(
              authDetails?.email,
              authDetails?.password,
              authDetails?.userName?.toLowerCase()
            )
          );
          setIsStatus(false);
          if (status?.status === 200) {
            let logInStatus = await dispatch(
              loginUser(authDetails?.email, authDetails?.password, coords)
            );
            if (logInStatus?.status === 200) {
              const timer = setTimeout(() => {
                setIsEmailSendModal(true);
                setIsSignUp(false);
                setIsAuthModal(false);
                setIsPasswordShow({});
                setAuthDetails(isSignUp ? isSignUpErr : isLogInErr);
                setLoginError({});
              }, 2000);
              return () => clearTimeout(timer);
            }
          } else if (status?.status === 204) {
            setIsWarningModal(true);
            setIsSignUp(false);
            setIsAuthModal(false);
            setIsPasswordShow({});
            setAuthDetails(isSignUp ? isSignUpErr : isLogInErr);
            setLoginError({});
          }
          setIsStatus(false);
        } else {
          status = await dispatch(
            loginUser(authDetails?.email, authDetails?.password, coords)
          );
          if (status?.status === 200) {
            const timer = setTimeout(() => {
              if (typeof window !== undefined) {
                window.location.reload();
              }
              setIsStatus(false);
              setIsAuthModal(false);
              setIsSignUp(false);
              setIsPasswordShow({});
            }, 3000);
            return () => clearTimeout(timer);
          } else if (status?.status === 401) {
            setIsStatus(false);
            setLoginError({
              ...loginError,
              auth: "Invalid Credential",
            });
          } else if (status?.status === 404) {
            setIsStatus(false);
            setLoginError({
              ...loginError,
              auth: "User not found",
            });
          } else {
            setIsStatus(false);
            setLoginError({
              ...loginError,
              auth: "",
            });
          }
        }
      }
    }
  };
  const handleAuthKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmitLoginData(e);
    }
  };
  const handleIsShowPassword = (name) => {
    setIsPasswordShow({
      ...isPasswordShow,
      [name]: true,
    });
  };
  const handleIsShowPasswordInvisible = (name) => {
    setIsPasswordShow({
      ...isPasswordShow,
      [name]: false,
    });
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCoords({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        () => {
          setCoords({
            latitude: "",
            longitude: "",
          });
        }
      );
    } else {
      setCoords({
        latitude: "",
        longitude: "",
      });
    }
  }, []);

  return (
    <AuthenticationView
      {...{
        isStatus,
        isWarningModal,
        inputRef,
        isAuthModal,
        isSignUp,
        authDetails,
        loginError,
        setIsWarningModal,
        handleCancelAuthModal,
        handleClickSignIn,
        handleInputChange,
        handleAuthBlur,
        handleAuthKeyDown,
        handleSubmitLoginData,
        handleForgotPassword,
        handleGoogleLogin,
        isEmailSendModal,
        setIsEmailSendModal,
        isPasswordShow,
        handleIsShowPassword,
        handleIsShowPasswordInvisible,
      }}
    />
  );
};

export default AuthenticationContainer;
