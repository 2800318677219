import React from "react";
import "../../../../styles/card/card.scss";
import Link from "next/link";
import { apiConst } from "@/src/const/apiConst";
import TooltipComponent from "../Tooltip/TooltipComponent";
const ViewCountComponent = ({ handleWebsiteViewCount, websiteDataObj }) => {
  return (
    <div className={"image-open-icon-main"}>
      <TooltipComponent
        {...{
          tooltipName: "Visit Website",
        }}
      />
      <Link
        onClick={(e) => handleWebsiteViewCount(e, websiteDataObj?.websiteId)}
        href={
          websiteDataObj
            ? `${websiteDataObj?.websiteUrl}/${apiConst.REF_URL}`
            : "/"
        }
        target="_blank"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 20 25"
          version="1.1"
          x="0px"
          y="0px"
          className={"image-open-icon"}
        >
          <g stroke="none" strokeWidth="1" fill="#fff" fillRule="evenodd">
            <g transform="translate(-380.000000, -6599.000000)" fill="#fff">
              <g transform="translate(56.000000, 160.000000)">
                <polygon points="324 6457.602 325.393 6458.997 342 6442.349 342 6453 344 6453 344 6439 329.907 6439 329.877 6441 340.634 6441" />
              </g>
            </g>
          </g>
        </svg>
      </Link>
    </div>
  );
};

export default ViewCountComponent;
