"use client";
import React, { useEffect, useRef, useState } from "react";
import WebsiteCardView from "./WebsiteCardView";
import WebsiteCardCarouselView from "./WebsiteCardCarouselView";
import {
  addCollectionData,
  categoryCarousel,
  getWebsiteById,
  websiteCount,
} from "@/src/redux/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonComponent,
  ImageComponent,
  InputComponent,
  SuccessModal,
  WebsiteDetailsContainer,
} from "..";
import { Col, Form, Modal, Row } from "antd";
import Image from "next/image";
import {
  categoryCarouselCollection,
  collectionDataUpdateTopTen,
  filterWebsiteById,
  saveDataFilterUpdate,
  setIsUserNameApplied,
  websiteCollectionUpdate,
} from "@/src/redux/reducers/slices";
import Link from "next/link";
import { isEmpty } from "@/src/utils";
import "../../../../styles/websiteCard/websiteCard.scss";
import { addWebsiteValidation } from "@/src/utils/validation/validation";
import { getUserLatestData } from "@/src/redux/actions/getApis/GetApis";

const WebsiteCardContainer = (props) => {
  const {
    slidesToShowImage,
    isScreenShotOpen,
    isCarousel,
    token,
    data,
    isMyWebsite,
    handleUserWebsiteEdit,
    handleUserWebsiteDelete,
    isDetailsPage,
    isLoadingTopWebsite,
    scrollToTop,
    isMyPortfolio,
  } = props;
  const [websiteCardData, setWebsiteCardData] = useState([]);
  const [isPortfolioWebsite, setIsPortfolioWebsite] = useState(true);
  const [userOnHoverFilterData, setUserOnHoverFilterData] = useState([]);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [collectionError, setCollectionError] = useState("");
  const { isLoadingWebsiteState, websitesFilter } = useSelector(
    (state) => state.website
  );
  const [drawer, setDrawer] = useState({
    isLink: false,
    isOpen: false,
    isModalOpen: false,
    isLoading: false,
    isDetailsPage: false,
    data: {},
  });
  const [modal, setModal] = useState({
    isAddCollection: false,
    isCreateCollection: false,
    collectionName: "",
    id: "",
    image: "",
    imgAlt: "",
  });
  const { manageCollection } = useSelector((state) => state.collection);
  const { userNameApplied } = useSelector((state) => state.website);
  const { userPortfolioProfileData } = useSelector(
    (state) => state.userPortfolio
  );
  const dispatch = useDispatch();
  useEffect(() => {
    setWebsiteCardData(data || []);
  }, [data]);
  const timeoutRef = useRef(null);

  const handleOnMouseHover = async (id, websiteId) => {
    if (userNameApplied !== websiteId || isEmpty(userOnHoverFilterData)) {
      setIsPortfolioWebsite(true);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(async () => {
        const response = await getUserLatestData(id);
        setUserOnHoverFilterData(response);
        dispatch(setIsUserNameApplied(websiteId));
        setIsPortfolioWebsite(false);
      }, 500);
    }
  };
  const handleDrawerOpen = async (id, e, category) => {
    // e?.stopPropagation();
    if (e?.ctrlKey) {
      setDrawer({
        ...drawer,
        isLink: true,
        isOpen: false,
      });
      window.open(`/view-details/${id}`);
    } else {
      let data = drawer?.isOpen
        ? { ...drawer, isLoading: true }
        : {
            ...drawer,
            isOpen: true,
            isLoading: true,
          };
      setDrawer((prev) => ({ ...prev, ...data }));
      const websiteRes = await getWebsiteById(id);
      await dispatch(filterWebsiteById(websiteRes));
      data = { ...data, isLoading: false, data: websiteRes };
      setDrawer((prev) => ({ ...prev, ...data, isOpen: true }));
      if (!isEmpty(category)) {
        await dispatch(categoryCarousel(id, category));
      }
    }
  };
  const handleCollectionChange = (e) => {
    let { value } = e.target;
    value = value?.trimStart();
    setModal({ ...modal, collectionName: value });
  };
  const handleSaveCollection = (name) => {
    const { errors, isValid } = addWebsiteValidation(
      name,
      modal,
      collectionError
    );
    setCollectionError(errors);
    if (isValid === false) {
      if (modal?.collectionName) {
        name = modal?.collectionName;
      }
      setModal({
        ...modal,
        isAddCollection: false,
        isCreateCollection: false,
      });
      let updatedData = {
        id: modal?.id,
        isCollection: true,
      };
      const allUpdatedDataWebsite = data?.map((obj) => {
        if (obj?.websiteId === updatedData?.id) {
          let a = {
            ...obj,
            isCollection: updatedData?.isCollection,
          };
          return a;
        } else {
          return obj;
        }
      });
      setWebsiteCardData(allUpdatedDataWebsite || []);
      const updatedScreenshotDetails = {
        ...websitesFilter,
        isCollection: updatedData?.isCollection,
      };
      setDrawer({
        ...drawer,
        data: updatedScreenshotDetails,
        isOpen: false,
      });
      dispatch(websiteCollectionUpdate(updatedData));
      dispatch(collectionDataUpdateTopTen(updatedData));
      dispatch(categoryCarouselCollection(updatedData));
      dispatch(saveDataFilterUpdate(updatedScreenshotDetails));
      const payload = {
        websiteId: modal?.id,
        collectionsName: name,
      };
      dispatch(addCollectionData(payload));
      setIsSuccessModal(true);
      setCollectionError("");
      setModal({
        isAddCollection: false,
        isCreateCollection: false,
        collectionName: "",
        id: "",
      });
    }
  };
  const handleAddCollectionName = () => {
    setModal({
      ...modal,
      isAddCollection: false,
      isCreateCollection: true,
    });
  };
  const handleCancelModel = () => {
    setModal({
      ...modal,
      isAddCollection: false,
      isCreateCollection: false,
    });
  };
  const handleDrawerClose = () => {
    if (!isEmpty(data)) {
      setDrawer({
        isLink: false,
        isOpen: false,
        isModalOpen: false,
        isLoading: false,
        isDetailsPage: false,
        data: {},
      });
    }
  };
  const handleWebsiteViewCount = async (e, id) => {
    e.stopPropagation();
    await dispatch(websiteCount(id));
  };
  const handleOnMouseLeave = () => {
    setUserOnHoverFilterData([]);
  };
  return (
    <React.Fragment>
      {isCarousel ? (
        <WebsiteCardCarouselView
          {...{
            slidesToShowImage,
            websiteCardData,
            isScreenShotOpen,
            token,
            modal,
            setModal,
            setWebsiteCardData,
            handleDrawerOpen,
            manageCollection,
            drawer,
            setDrawer,
            handleSaveCollection,
            handleAddCollectionName,
            isLoadingTopWebsite,
            handleWebsiteViewCount,
            scrollToTop,
            handleOnMouseHover,
            userOnHoverFilterData,
            userNameApplied,
            isCarousel,
            handleOnMouseLeave,
            isPortfolioWebsite,
          }}
        />
      ) : (
        !isDetailsPage && (
          <WebsiteCardView
            {...{
              manageCollection,
              token,
              websiteCardData,
              drawer,
              modal,
              setModal,
              setWebsiteCardData,
              setDrawer,
              isMyWebsite,
              collectionError,
              handleUserWebsiteEdit,
              handleUserWebsiteDelete,
              handleDrawerOpen,
              handleSaveCollection,
              handleAddCollectionName,
              isLoadingWebsiteState,
              handleWebsiteViewCount,
              handleOnMouseHover,
              userOnHoverFilterData,
              userNameApplied,
              isCarousel,
              handleOnMouseLeave,
              isMyPortfolio,
              userPortfolioProfileData,
              isPortfolioWebsite,
            }}
          />
        )
      )}
      {(drawer?.isOpen || drawer?.isLink || isDetailsPage) && (
        <WebsiteDetailsContainer
          {...{
            manageCollection,
            token,
            isMyWebsite,
            isOpen: drawer?.isOpen,
            handleDrawerClose,
            drawer,
            modal,
            setModal,
            setDrawer,
            handleDrawerOpen,
            data: websiteCardData,
            setWebsiteCardData,
            handleSaveCollection,
            handleWebsiteViewCount,
          }}
        />
      )}
      {modal?.isAddCollection && (
        <Modal
          open={modal?.isAddCollection}
          onCancel={handleCancelModel}
          className={"add-collection"}
          closeIcon={false}
          centered
          footer={false}
        >
          <div className={"add-collection-title"}>Save to your Collection</div>
          <div className={"add-collection-subtitle"}>All Collection</div>
          <div className={"add-collection-main"}>
            <div
              onClick={() => handleAddCollectionName()}
              className={"add-new-collection"}
            >
              <div className={"add-new-collection_icon"}>+</div>
            </div>
            {!isEmpty(manageCollection) &&
              Object.keys(manageCollection)
                ?.slice(0, 5)
                ?.map((ele) => {
                  return (
                    <div key={ele} className={"collection-data-wrap"}>
                      <div
                        key={ele}
                        onClick={() => handleSaveCollection(ele)}
                        className={"collection-data"}
                      >
                        <div className={"collection-data-overly"}>
                          <div className={"collection-overlay-icon"}>+</div>
                        </div>
                        <ImageComponent
                          imageSrc={
                            manageCollection[ele]?.[
                              manageCollection[ele]?.length - 1
                            ]?.screenShotImg
                          }
                          imageAlt="collectionTheme"
                          imageClassName={"collection-image"}
                          imageWidth={100}
                          imageHeight={170}
                        />
                      </div>
                      <h5 className={"collection-name"}>{ele}</h5>
                    </div>
                  );
                })}
            {Object.keys(manageCollection)?.length > 5 && (
              <Link href={"/collection"} className={"collection-modal-button"}>
                <ButtonComponent
                  btnText={"View More"}
                  classNames={"view-more-button"}
                />
              </Link>
            )}
          </div>
        </Modal>
      )}
      {modal?.isCreateCollection && (
        <Modal
          open={modal?.isCreateCollection}
          onCancel={handleCancelModel}
          footer={false}
          closeIcon={false}
          centered
          className={"create-collection"}
        >
          <p className={"create-collection-Portfolio-title"}>{modal?.imgAlt}</p>
          <Row className={"create-collection-wrap"}>
            <Col
              span={24}
              xxl={12}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              className={"create-collection-left"}
            >
              <div className={"create-collection-portfolio-mein"}>
                <Image
                  src={modal?.image}
                  alt={modal?.imgAlt}
                  width={500}
                  height={500}
                  className={"create-collection-portfolio-image"}
                />
              </div>
            </Col>
            <Col
              span={24}
              xxl={12}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              className={"create-collection-details"}
            >
              <div className={"create-collection-details-wrap"}>
                <p className={"create-collection-title"}>
                  create new collection
                </p>
                <Form className={"form"}>
                  <Form.Item className={"form_item"}>
                    <label className={"form-item-label"}>
                      {"Collection Name"}
                    </label>
                    <InputComponent
                      type={"text"}
                      handleChange={(e) => handleCollectionChange(e)}
                      name={"collectionName"}
                      placeholder={"Collection name"}
                      error={collectionError?.collectionName}
                      classNames={"form-item-input"}
                    />
                  </Form.Item>
                </Form>
                <div className={"collection-btn-wrap"}>
                  <ButtonComponent
                    btnText={"Cancel"}
                    handleClick={handleCancelModel}
                  />
                  <ButtonComponent
                    btnText={"Save"}
                    handleClick={() => handleSaveCollection("collectionName")}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Modal>
      )}
      {!isSuccessModal && (
        <SuccessModal
          {...{
            isSuccessModal,
            setIsSuccessModal,
            descriptionText: "Your Collection Added Successfully",
          }}
        />
      )}
    </React.Fragment>
  );
};

export default WebsiteCardContainer;
