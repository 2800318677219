import {
  postWithoutToken,
  post,
} from "@/src/utils/axiosInterceptor/AxiosInterceptor";
import { apiConst } from "@/src/const/apiConst";

export const sendPasswordEmail = (email) => {
  return async () => {
    const res = await postWithoutToken(`${apiConst.FORGOT_PASSWORD_EMAIL}`, {
      emailId: email,
    });
    if (res) {
      return res;
    }
  };
};
export const forgotPasswordAction = (obj) => {
  return async () => {
    try {
      const res = await postWithoutToken(
        `${apiConst.FORGOT_PASSWORD(obj?.token)}`,
        {
          password: obj?.password,
        }
      );
      if (res?.status === 200) {
        return res;
      }
    } catch (error) {
      return error;
    }
  };
};
export const resetPasswordAction = (obj) => {
  return async () => {
    let res = await post(`${apiConst.RESET_PASSWORD}`, obj);
    return res;
  };
};
