import { getToken } from "@/src/app/actions";
import { apiConst } from "@/src/const/apiConst";
import axios from "axios";
import isTokenValid from "../token";
import isEmpty from "../isEmpty/isEmpty";

const { BASE_URL } = apiConst;

const axiosWithOutToken = {
  "Content-Type": "application/json",
};

/**
 * Create a custom Error object with additional status information.
 * @param {string} message - The error message
 * @param {number} status - The Http status code of the error
 * @param {Error} - The custom Error object
 */

const createError = (message) => {
  if (message?.response?.status === 500) {
    console.log("Internal server error", "error");
  } else if (message?.response?.status === 400) {
    console.log(message?.response?.data?.message, "error");
  } else if (message?.response?.status === 401) {
    if (message?.response?.data?.message !== "Invalid credentials") {
      console.log(message?.response?.data?.message, "error");
    }
  } else if (message?.response?.status === 404) {
    if (message?.response?.data?.message !== "User not found.") {
      console.log(message?.response?.data?.message, "error");
    }
  } else if (message?.response?.status === 502) {
    console.log("Internal server error", "error");
  } else if (message?.response?.status === 504) {
    console.log("oops! Something went wrong", "error");
  } else {
    console.log("An error occurred", "error");
  }
  return message?.response;
};

/**
 * Handle the axios response and return the data or throw with the message
 * @param {object}  response The Axios response object
 * @param {object} - The data returned by the API
 * @param {Error} - Throw the Error with the API error message if the status is not 200 or 201
 */

const handleResponse = (response) => {
  const isValid = isTokenValid(getToken());
  if (!isValid && !isEmpty(getToken())) {
    window.location.href = "/";
  } else {
    return response;
  }
};

const handleRequestError = (error) => {
  const isValid = isTokenValid(getToken());
  if (!isValid && !isEmpty(getToken())) {
    window.location.href = "/";
    deleteToken();
  } else {
    if (
      error?.response?.data?.message !==
        "You have already disliked this post." &&
      error?.response?.data?.message !== "You have already liked this post."
    ) {
      return createError(error);
    }
  }
};

const apiInstance = axios.create({
  baseURL: BASE_URL,
});

const apiInstanceWithoutToken = axios.create({
  baseURL: BASE_URL,
  headers: axiosWithOutToken,
  withCredentials: true,
});

const apiInstanceFormData = axios.create({
  baseURL: BASE_URL,
});

apiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.data && error.response) {
      return error.response;
    }
    return Promise.reject(error);
  }
);

/**
 * Perform an HTTP GET request to the specified URL.
 * @param {string} url - The URL to send the GET request to.
 * @param {Object} params - The optional query parameters.
 * @returns {Object} - The response data from the API.
 * @throws {Error} - Throws an error if the request fails.
 */
const getWithToken = async (url, params) => {
  try {
    const token = await getToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const response = await apiInstance.get(url, { params, headers });
    return handleResponse(response);
  } catch (error) {
    handleRequestError(error);
  }
};

/**
 * Perform an HTTP GET request to the specified URL.
 * @param {string} url - The URL to send the GET request to.
 * @param {Object} params - The optional query parameters.
 * @returns {Object} - The response data from the API.
 * @throws {Error} - Throws an error if the request fails.
 */
const getWithoutToken = async (url, params) => {
  try {
    const response = await apiInstanceWithoutToken.get(url, { params });
    return handleResponse(response);
  } catch (error) {
    handleRequestError(error);
  }
};

/**
 * Perform an HTTP POST request to the specified URL.
 * @param {string} url - The URL to send the POST request to.
 * @param {Object} data - The data to be sent in the request body.
 * @param {Object} params - The optional query parameters.
 * @returns {Object} - The response data from the API.
 * @throws {Error} - Throws an error if the request fails.
 */
const post = async (url, data, params) => {
  try {
    const token = await getToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const response = await apiInstance.post(url, data, { params, headers });
    return handleResponse(response);
  } catch (error) {
    return handleRequestError(error);
  }
};

/**
 * Perform an HTTP POST request to the specified URL.
 * @param {string} url - The URL to send the POST request to.
 * @param {Object} data - The data to be sent in the request body.
 * @param {Object} params - The optional query parameters.
 * @returns {Object} - The response data from the API.
 * @throws {Error} - Throws an error if the request fails.
 */
const postWithoutToken = async (url, data, params = {}, extraHeaders = {}) => {
  try {
    const response = await apiInstanceWithoutToken.post(url, data, {
      params,
      headers: extraHeaders,
    });
    return handleResponse(response);
  } catch (error) {
    return handleRequestError(error);
  }
};

/**
 * Perform an HTTP PUT request to the specified URL.
 * @param {string} url - The URL to send the PUT request to.
 * @param {Object} data - The data to be sent in the request body.
 * @param {Object} params - The optional query parameters.
 * @returns {Object} - The response data from the API.
 * @throws {Error} - Throws an error if the request fails.
 */
const patch = async (url, data, params) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    };
    const response = await apiInstance.put(url, data, { params, headers });
    return handleResponse(response);
  } catch (error) {
    return handleRequestError(error);
  }
};

const patchWithoutToken = async (url, data, params) => {
  try {
    const response = await apiInstanceWithoutToken.put(url, data, { params });
    return handleResponse(response);
  } catch (error) {
    return handleRequestError(error);
  }
};

/**
 * Perform an HTTP DELETE request to the specified URL.
 * @param {string} url - The URL to send the DELETE request to.
 * @param {Object} params - The optional query parameters.
 * @returns {Object} - The response data from the API.
 * @throws {Error} - Throws an error if the request fails.
 */
const remove = async (url, params) => {
  try {
    const token = await getToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const response = await apiInstance.delete(url, { params, headers });
    return handleResponse(response);
  } catch (error) {
    return handleRequestError(error);
  }
};

const removeAll = async (url, data, params) => {
  try {
    const token = await getToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const response = await apiInstance.delete(
      url,
      { data, headers },
      { params }
    );
    return handleResponse(response);
  } catch (error) {
    return handleRequestError(error);
  }
};

const postWithFileFormData = async (url, formData) => {
  try {
    const token = await getToken();
    const headers = {
      "Content-Type": "multipart/form-data",
      Accept: "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    const response = await apiInstanceFormData.post(url, formData, {
      headers,
    });
    return handleResponse(response);
  } catch (error) {
    return handleRequestError(error);
  }
};

const updateWithFileFormData = async (url, formData, params) => {
  try {
    const token = await getToken();
    const headers = {
      "Content-Type": "multipart/form-data",
      Accept: "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    const response = await apiInstanceFormData.put(url, formData, {
      params,
      headers,
    });
    return handleResponse(response);
  } catch (error) {
    return handleRequestError(error);
  }
};

export {
  getWithToken,
  post,
  patch,
  remove,
  removeAll,
  postWithoutToken,
  getWithoutToken,
  updateWithFileFormData,
  postWithFileFormData,
  patchWithoutToken,
};
