"use client";
import React, { useEffect, useLayoutEffect, useState } from "react";
import WebsiteDetailsView from "./WebsiteDetailsView";
import { useDispatch, useSelector } from "react-redux";
import { useNav } from "../context/NavbarProvider";
import { useParams } from "next/navigation";
import {
  filterWebsiteById,
  userProfileData,
} from "@/src/redux/reducers/slices";
import { getUserProfileData, getWebsiteById } from "@/src/redux/actions";
import { apiConst } from "@/src/const/apiConst";
import { isEmpty } from "@/src/utils";

const WebsiteDetailsContainer = (props) => {
  const {
    manageCollection,
    token,
    data,
    isMyWebsite,
    isOpen,
    handleDrawerClose,
    drawer,
    modal,
    setModal,
    setDrawer,
    setWebsiteCardData,
    handleDrawerOpen,
    handleWebsiteCardLike,
    handleWebsiteViewCount,
    handleWebsiteCardCollection,
    handleSaveCollection,
  } = props;
  const [screenSize, setScreenSize] = useState();
  const [isCopiedColor, setIsCopiedColor] = useState(false);
  const [isCopiedUrl, setIsCopiedUrl] = useState(false);
  const [isCopiedImage, setIsCopiedImage] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const { profileData } = useSelector((state) => state.profile);
  const { websitesFilter, categoryFilter } = useSelector(
    (state) => state.website
  );
  const dispatch = useDispatch();
  const params = useParams();

  const { setFontFamilyValue, fontFamilyValue, isAuthModal, setIsAuthModal } =
    useNav();
  useEffect(() => {
    setScreenSize(window.innerWidth);
  }, []);

  useEffect(() => {
    if (!isEmpty(websitesFilter)) {
      const defaultFont = websitesFilter?.fontFamily?.split(",");
      setFontFamilyValue({
        ...fontFamilyValue,
        fontFamilyName: isEmpty(defaultFont) ? "poppins" : defaultFont[0],
      });
    }
  }, [websitesFilter]);

  useLayoutEffect(() => {
    const id = params?.id;
    if (id) {
      let disabled = window.location.pathname !== "/" ? true : false;
      setDrawer({
        ...drawer,
        isDetailsPage: disabled,
        isLink: true,
      });
      const getWebsiteData = async () => {
        setDrawer((prev) => ({ ...prev, isLoading: true }));
        const response = await getWebsiteById(id);
        await dispatch(filterWebsiteById(response));
        if (response) {
          setDrawer((prev) => ({ ...prev, isLoading: false }));
        }
        setDrawer({
          ...drawer,
          isLink: true,
          data: response,
        });
      };

      getWebsiteData();
      if (token) {
        const handleFetchWebsiteData = async () => {
          const res = await getUserProfileData();
          dispatch(userProfileData(res));
        };
        handleFetchWebsiteData();
      }
    }
  }, [params?.id, token]);

  const handleCopyImage = async (imageUrl) => {
    if (token) {
      try {
        let response = await fetch(imageUrl);
        let blob = await response.blob();
        const img = new Image();
        img.src = URL.createObjectURL(blob);
        img.onload = async () => {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          canvas.toBlob(async (pngBlob) => {
            const data = new ClipboardItem({ "image/png": pngBlob });
            // Copy the png image to the clipboard
            await navigator.clipboard.write([data]);
          }, "image/png");
        };
        setIsCopiedImage(true);
        const timer = setTimeout(() => {
          setIsCopiedImage(false);
        }, 700);
        return () => clearTimeout(timer);
      } catch (error) {
        return error;
      }
    } else {
      setIsAuthModal(true);
    }
  };
  const handleCopyText = (value, name) => {
    if (name === "color".toLowerCase()) {
      navigator.clipboard.writeText(value);
      setIsCopiedColor(true);
      const timer = setTimeout(() => {
        setIsCopiedColor(false);
      }, 500);
      return () => clearTimeout(timer);
    } else {
      let URL = `${apiConst.FRONTEND_URL}/view-details/${value}`;
      navigator.clipboard.writeText(URL);
      setIsCopiedUrl(true);
      const timer = setTimeout(() => {
        setIsCopiedUrl(false);
      }, 800);
      return () => clearTimeout(timer);
    }
  };
  const handleModalOpen = () => {
    setDrawer({
      ...drawer,
      isModalOpen: true,
    });
  };
  const handleCancelShareIconModal = () => {
    setDrawer({
      ...drawer,
      isModalOpen: false,
    });
  };
  const handleChangeFontFamily = async (value) => {
    setFontFamilyValue({
      isFontFamily: false,
      fontFamilyName: value,
    });
  };
  const handleOpenImageModal = () => {
    if (token) {
      setIsImageModalOpen(!isImageModalOpen);
    } else {
      setIsAuthModal(true);
    }
  };
  return (
    <WebsiteDetailsView
      {...{
        manageCollection,
        data,
        token,
        isMyWebsite,
        isCopiedImage,
        isCopiedUrl,
        isCopiedColor,
        isOpen,
        categoryFilter,
        drawer,
        profileData,
        isAuthModal,
        fontFamilyValue,
        modal,
        setWebsiteCardData,
        setModal,
        setDrawer,
        screenSize,
        setDrawer,
        setIsAuthModal,
        handleDrawerOpen,
        handleDrawerClose,
        handleCopyImage,
        handleWebsiteCardLike,
        handleWebsiteViewCount,
        handleChangeFontFamily,
        handleCancelShareIconModal,
        handleModalOpen,
        handleCopyText,
        handleWebsiteCardCollection,
        handleSaveCollection,
        handleOpenImageModal,
        isImageModalOpen,
      }}
    />
  );
};

export default WebsiteDetailsContainer;
