import React from "react";
import CardDetailsPageView from "./CardDetailsPageView";
import { Drawer } from "antd";
import "../../../../styles/screenShotCardDetails/cardDetails.scss";
import { apiConst } from "@/src/const/apiConst";

const WebsiteDetailsView = (props) => {
  const {
    manageCollection,
    data,
    token,
    isMyWebsite,
    setWebsiteCardData,
    isCopiedImage,
    isCopiedUrl,
    isCopiedColor,
    isOpen,
    handleDrawerClose,
    categoryFilter,
    drawer,
    profileData,
    isAuthModal,
    modal,
    fontFamilyValue,
    setModal,
    setDrawer,
    setIsAuthModal,
    handleDrawerOpen,
    handleWebsiteCardLike,
    handleCopyImage,
    handleChangeFontFamily,
    handleCancelShareIconModal,
    handleModalOpen,
    handleCopyText,
    handleWebsiteViewCount,
    handleWebsiteCardCollection,
    handleSaveCollection,
    handleOpenImageModal,
    isImageModalOpen,
  } = props;
  const path =
    typeof window !== "undefined" &&
    window.location.pathname?.includes("view-details");
  return (
    <React.Fragment>
      {!isOpen && drawer?.isLink && path ? (
        <div className={"screenshot_image_drawer_wrap"}>
          <CardDetailsPageView
            {...{
              manageCollection,
              data,
              token,
              isMyWebsite,
              isCopiedImage,
              isCopiedUrl,
              isCopiedColor,
              setWebsiteCardData,
              isLink: drawer?.isLink,
              isModalOpen: drawer?.isModalOpen,
              filterData: drawer?.data,
              fontFamilyValue,
              isLoading: drawer?.isLoading,
              url: `${apiConst.FRONTEND_URL}/view-details/${drawer?.data?.websiteId}`,
              drawer,
              modal,
              setModal,
              setDrawer,
              profileData,
              categoryFilter,
              isAuthModal,
              setIsAuthModal,
              handleModalOpen,
              handleCancelShareIconModal,
              handleCopyImage,
              handleCopyText,
              handleChangeFontFamily,
              handleDrawerClose,
              handleWebsiteViewCount,
              handleDrawerOpen,
              handleWebsiteCardLike,
              handleWebsiteCardCollection,
              handleSaveCollection,
              handleOpenImageModal,
              isImageModalOpen,
            }}
          />
        </div>
      ) : (
        <Drawer
          placement="bottom"
          closable={false}
          onClose={drawer?.isLoading === false ? handleDrawerClose : () => {}}
          open={isOpen}
          className={"screenshot_image_drawer_wrap"}
          height={"95vh"}
          style={{ maxWidth: "2000px", margin: "0 auto" }}
        >
          <CardDetailsPageView
            {...{
              manageCollection,
              data,
              token,
              isMyWebsite,
              isCopiedImage,
              isCopiedUrl,
              isCopiedColor,
              setWebsiteCardData,
              isLink: drawer?.isLink,
              isModalOpen: drawer?.isModalOpen,
              filterData: drawer?.data,
              fontFamilyValue,
              isLoading: drawer?.isLoading,
              url: `${apiConst.FRONTEND_URL}/view-details/${drawer?.data?.websiteId}`,
              drawer,
              modal,
              categoryFilter,
              setModal,
              setDrawer,
              profileData,
              handleModalOpen,
              handleCancelShareIconModal,
              handleCopyImage,
              handleCopyText,
              handleChangeFontFamily,
              handleDrawerClose,
              handleWebsiteViewCount,
              handleDrawerOpen,
              handleWebsiteCardLike,
              handleWebsiteCardCollection,
              handleSaveCollection,
              isAuthModal,
              handleOpenImageModal,
              isImageModalOpen,
            }}
          />
        </Drawer>
      )}
    </React.Fragment>
  );
};

export default WebsiteDetailsView;
