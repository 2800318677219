import React, { useRef } from "react";
import "../../../../styles/screenShotCardDetails/cardDetails.scss";
import { Modal, Row, Col, Skeleton } from "antd";
import { ShareAltOutlined } from "@ant-design/icons";
import { ALPHABETS } from "@/src/const/nonPrimitive";
import Link from "next/link";
import {
  TwitterShareButton,
  LinkedinShareButton,
  FacebookShareButton,
  WhatsappShareButton,
} from "react-share";
import { apiConst } from "@/src/const/apiConst";
import { UserVerified, filterGetColor, isEmpty } from "@/src/utils";
import Image from "next/image";
import { getTimeAgo } from "@/src/utils/date/date";
import UserProfileImage from "../userProfileImage/userProfileImage";
import WebsiteCardCarouselView from "../WebsiteCards/WebsiteCardCarouselView";
import {
  ButtonComponent,
  CollectionContainer,
  ImageComponent,
  LikeContainer,
} from "..";
import {
  closes,
  copy,
  copyBold,
  facebook,
  linkedin,
  newTab,
  twitter,
  whatsapp,
  whiteLogo,
} from "../../../../public/assets";
import TooltipComponent from "../Tooltip/TooltipComponent";
import AuthenticationContainer from "../../PagesComponents/NavComponent/Indicators/Auth/AuthContainer";

const CardDetailsPageView = ({
  manageCollection,
  data,
  token,
  isMyWebsite,
  setWebsiteCardData,
  isCopiedImage,
  isCopiedUrl,
  isCopiedColor,
  isLoading,
  isLink,
  filterData,
  isModalOpen,
  fontFamilyValue,
  url,
  drawer,
  modal,
  setModal,
  setDrawer,
  profileData,
  categoryFilter,
  handleModalOpen,
  handleCopyImage,
  handleCopyText,
  handleChangeFontFamily,
  handleDrawerClose,
  handleWebsiteViewCount,
  handleDrawerOpen,
  handleWebsiteCardLike,
  handleWebsiteCardCollection,
  handleCancelShareIconModal,
  handleSaveCollection,
  isAuthModal,
  handleOpenImageModal,
  isImageModalOpen,
}) => {
  const scrollRef = useRef(null);
  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
      const start = scrollRef.current.scrollTop;
      const end = 0;
      const duration = 500; // Adjust the duration as needed (in milliseconds)
      const startTime = performance.now();
      function easeInOutQuad(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      }
      function scroll(time) {
        const elapsed = time - startTime;
        scrollRef.current.scrollTop = easeInOutQuad(
          elapsed,
          start,
          end - start,
          duration
        );

        if (elapsed < duration) {
          requestAnimationFrame(scroll);
        }
      }
      requestAnimationFrame(scroll);
    }
  };
  return (
    <div className={"drawer_mains"}>
      <div
        className={`${"drawer_header_main"} ${isLink ? "new_page_header" : ""}`}
      >
        <div className={"drawer_header_wrap"}>
          {isLink ? (
            <Link
              href="/"
              className={"back_main"}
              onClick={
                drawer?.isLoading === false ? handleDrawerClose : () => {}
              }
            >
              <svg
                width="32"
                height="30"
                viewBox="0 0 32 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={"back-icon"}
              >
                <path
                  d="M9.58031 23.5179L5.30348 9.00572L20.1872 5.50643M6.63407 9.7705L26.0608 20.9363"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className={"back-btn-text"}>Back</p>
            </Link>
          ) : (
            <div
              className={"back_main"}
              onClick={
                drawer?.isLoading === false ? handleDrawerClose : () => {}
              }
            >
              <svg
                width="32"
                height="30"
                viewBox="0 0 32 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={"back-icon"}
              >
                <path
                  d="M9.58031 23.5179L5.30348 9.00572L20.1872 5.50643M6.63407 9.7705L26.0608 20.9363"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className={"back-btn-text"}>Back</p>
            </div>
          )}
          <div className={"screenshot_share_open_main"}>
            <div className="copy_image_main">
              <ButtonComponent
                handleClick={() =>
                  handleCopyImage(`${filterData?.screenShotImg}`)
                }
                classNames={"copy_img_button"}
                btnText={"Copy Image"}
                disabled={isLoading && true}
              />
              <TooltipComponent
                {...{
                  tooltipName: isCopiedImage ? "Copied!" : "Click to Copy",
                }}
              />
            </div>
            <ShareAltOutlined
              className={`${"share_icon"} ${
                isMyWebsite && filterData?.status !== "approved"
                  ? "disabled_icon"
                  : ""
              }`}
              onClick={
                (isMyWebsite && filterData?.status !== "approved") || isLoading
                  ? () => {}
                  : handleModalOpen
              }
              placement="bottom"
            />
            {isLoading ? (
              <div
                className={`${isLoading ? "isload-disable" : ""} new_tab_icon_main`}
                disabled={true}
              >
                <ImageComponent
                  imageSrc={newTab}
                  imageAlt="New-Tab"
                  imageClassName={"new_tab_icon"}
                  imageWidth={23}
                  imageHeight={23}
                />
              </div>
            ) : (
              <Link
                className={"new_tab_icon_main"}
                href={
                  filterData
                    ? `${filterData?.websiteUrl}/${apiConst.REF_URL}`
                    : "/"
                }
                onClick={(e) =>
                  handleWebsiteViewCount(e, filterData?.websiteId)
                }
                target="_blank"
              >
                <ImageComponent
                  imageSrc={newTab}
                  imageAlt="New-Tab"
                  imageClassName={"new_tab_icon"}
                  imageWidth={23}
                  imageHeight={23}
                />
              </Link>
            )}
          </div>
        </div>
        {isLink && !profileData?.auth?.isVerified && <UserVerified />}
      </div>
      <div
        className={`${"drawer_wrap"} ${
          isLink ? "drawer_inherit" : "new_tab_drawer"
        }`}
        ref={scrollRef}
      >
        <div
          className={`${"screenshot_card_image_main"} ${
            isLink ? "new_page_card_image" : ""
          }`}
        >
          <div id="creator">
            <div>
              <div className={"screenshot_card_title_main"}>
                {isLoading ? (
                  <Skeleton.Input
                    active
                    className={"screenshot_card_title_skeleton"}
                  />
                ) : (
                  <h1 className={"website_name"}>{filterData?.agencyName}</h1>
                )}
                <div className={"profile_details_wrap"}>
                  {isLoading ? (
                    <Skeleton.Input active />
                  ) : (
                    <Link href={`/${filterData?.userName}`}>
                      <div className={"profile_image"}>
                        <UserProfileImage
                          profileImage={filterData?.profileImg || ""}
                          firstName={filterData?.firstName}
                          lastName={filterData?.lastName}
                          ImageClassName={"profile_image_wrap"}
                          imgHeight={45}
                          imgWidth={45}
                          characterClassName={"drawer_profile"}
                        />
                        <p className={"screenshot_card_title"}>
                          {filterData?.firstName} {filterData?.lastName}
                        </p>
                      </div>
                    </Link>
                  )}
                </div>
              </div>
              <Row
                gutter={30}
                className={`${"website_card_details_wrap"} ${
                  isLink ? "new_page_card_details_wrap" : ""
                }`}
              >
                <Col span={24} xs={24} sm={24} md={24} lg={24} xl={14} xxl={14}>
                  {isLoading ? (
                    <Skeleton.Image
                      className={"website_image_skeleton"}
                      active
                    />
                  ) : (
                    <div className={"screenshot_image"}>
                      <ImageComponent
                        imageSrc={filterData?.screenShotImg}
                        imageAlt={filterData?.alt}
                        imageClassName={"screenshot_card_image"}
                        imageHeight={593}
                        imageWidth={803}
                        imageSizes={`(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 33vw`}
                        handleClick={handleOpenImageModal}
                      />
                    </div>
                  )}
                </Col>
                <Col span={24} xs={24} sm={24} md={24} lg={24} xl={10} xxl={10}>
                  <div className={"website_details_main"}>
                    <div className={"modal_title_main"}>
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <h4 className={"website_title"}>{filterData?.title}</h4>
                      )}
                      <div className={"buttons_main"}>
                        {isLoading ? (
                          <Skeleton.Input
                            active
                            className={"buttons_skeleton"}
                          />
                        ) : (
                          <div
                            className={`${"save-btn-main"} ${
                              filterData?.isCollection ? "collection_save" : ""
                            } ${
                              isMyWebsite && filterData?.status !== "approved"
                                ? "disabled_icon"
                                : ""
                            }`}
                          >
                            <CollectionContainer
                              {...{
                                manageCollection,
                                token,
                                obj: filterData,
                                data,
                                isDetailsPage: true,
                                drawer,
                                modal,
                                setModal,
                                setDrawer,
                                setWebsiteCardData,
                                handleSaveCollection,
                              }}
                            />
                          </div>
                        )}
                        {isLoading ? (
                          <Skeleton.Input
                            active
                            className={"buttons_skeleton"}
                          />
                        ) : (
                          <div
                            className={`${"like_btn_main"} ${
                              filterData?.isLike ? "filter_like_main" : ""
                            } ${
                              isMyWebsite && filterData?.status !== "approved"
                                ? "disabled_icon"
                                : ""
                            }`}
                          >
                            <div
                              className={
                                filterData?.isLike
                                  ? "filterDetails_Like"
                                  : "like_button"
                              }
                            >
                              <LikeContainer
                                {...{
                                  token,
                                  obj: filterData,
                                  data,
                                  drawer,
                                  setDrawer,
                                  setWebsiteCardData,
                                  isDetailsPage: true,
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {isLoading ? (
                      <Skeleton.Input active className={"paragraph_skeleton"} />
                    ) : (
                      <p className={"website_information"}>
                        {filterData?.description}
                      </p>
                    )}
                    <div className={"website_color_information_main"}>
                      <div>
                        {isLoading ? (
                          <Skeleton.Input active />
                        ) : (
                          filterGetColor(filterData?.color)
                            ?.slice(0, 7)
                            ?.map((obj) => {
                              return (
                                <div
                                  style={{
                                    backgroundColor: obj,
                                  }}
                                  key={obj}
                                  className={"website_color_information"}
                                  onClick={() => handleCopyText(obj, "color")}
                                >
                                  <TooltipComponent
                                    {...{
                                      tooltipName: isCopiedColor
                                        ? "Copied!"
                                        : obj,
                                    }}
                                  />
                                  <Image
                                    alt={""}
                                    src={copy}
                                    height={15}
                                    width={15}
                                    className={"copy_icon"}
                                  />
                                </div>
                              );
                            })
                        )}
                      </div>
                      <div className={"last_seen_details_main"}>
                        {isLoading ? (
                          <Skeleton.Input
                            active
                            className={"website_Verified_skeleton"}
                          />
                        ) : (
                          <p>Verified {getTimeAgo(filterData?.createdAt)}</p>
                        )}
                      </div>
                    </div>
                    <div>
                      <h4 className={"industry_details"}>Category</h4>
                      <ul className={"industry_list_main"}>
                        {isLoading ? (
                          <Skeleton.Input
                            active
                            className={"industry_list_skeleton"}
                          />
                        ) : (
                          filterData?.category?.split(",")?.map((obj) => {
                            return (
                              <li key={obj} className={"industry_list"}>
                                <span>{obj}</span>
                              </li>
                            );
                          })
                        )}
                      </ul>
                    </div>
                    <div>
                      <h4 className={"industry_details"}>Style</h4>
                      <ul className={"industry_list_main"}>
                        {isLoading ? (
                          <Skeleton.Input
                            active
                            className={"industry_list_skeleton"}
                          />
                        ) : (
                          filterData?.style?.split(",")?.map((obj) => {
                            return (
                              <li key={obj} className={"industry_list"}>
                                <span>{obj}</span>
                              </li>
                            );
                          })
                        )}
                      </ul>
                    </div>
                  </div>
                </Col>
                {isImageModalOpen && (
                  <Modal
                    open={isImageModalOpen}
                    onCancel={handleOpenImageModal}
                    footer={false}
                    closeIcon={false}
                    className={"screenshot_image_modal"}
                    centered
                  >
                    <div className={"screenshot_image"}>
                      <ImageComponent
                        imageSrc={filterData?.screenShotImg}
                        imageAlt={filterData?.alt}
                        imageClassName={"screenshot_card_image"}
                        imageHeight={593}
                        imageWidth={803}
                        // imageSizes={`(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 33vw`}
                        handleClick={handleOpenImageModal}
                      />
                    </div>
                  </Modal>
                )}
              </Row>
            </div>
          </div>
          <div id="fontFamily" className="font-family-wrap">
            <h4 className={"font_style_title"}>Font Style</h4>
            <div className={"website_font_family_main"}>
              <div className={"website_uses_details_main"}>
                {isLoading ? (
                  <Skeleton.Input
                    active
                    className={"website_uses_details_skeleton"}
                  />
                ) : (
                  <p className={"website_uses_details"}>
                    This website utilizes{" "}
                    <span className={"website_counter"}>
                      {filterData?.fontFamily?.split(",")?.slice(0, 8)?.length}
                    </span>{" "}
                    font families
                  </p>
                )}
              </div>
              <div className={"font_style_name_main"}>
                {isLoading ? (
                  <Skeleton.Input
                    active
                    className={"font_style_name_skeleton"}
                  />
                ) : (
                  filterData?.fontFamily
                    ?.split(",")
                    ?.slice(0, 8)
                    ?.map((obj) => {
                      return (
                        <p
                          className={`${"font_family"} ${
                            fontFamilyValue?.fontFamilyName === obj
                              ? "current_family"
                              : ""
                          }`}
                          key={obj}
                          onClick={() => handleChangeFontFamily(obj)}
                        >
                          {obj}
                        </p>
                      );
                    })
                )}
              </div>
            </div>
            <h1 className={"font_family_name"}>
              {isLoading ? (
                <Skeleton.Input
                  active
                  className={"font_family_name_skeleton"}
                />
              ) : (
                <span
                  style={{
                    fontFamily: fontFamilyValue?.fontFamilyName,
                  }}
                >
                  {filterData?.title}
                </span>
              )}
            </h1>
            <div className={"font_family_information_main"}>
              <p className={"font_family_information"}>Font Details</p>
              <p className={"latter_font"}>LETTERS + NUMBERS</p>
            </div>
            <Row gutter={16} className={"font_style_view_main"}>
              <Col span={24} xs={24} sm={24} md={24} lg={24} xl={10} xxl={10}>
                <p
                  className={"font_styles"}
                  style={{
                    fontFamily: fontFamilyValue?.fontFamilyName,
                  }}
                >
                  Abcd
                </p>
              </Col>
              <Col span={24} xs={24} sm={24} md={24} lg={24} xl={14} xxl={14}>
                <div className={"font_latter_main"}>
                  {isLoading ? (
                    <Skeleton.Input active className={"font_family_skeleton"} />
                  ) : (
                    <React.Fragment>
                      {ALPHABETS?.map((obj) => {
                        return (
                          <div key={obj}>
                            <p
                              className={"font_latter"}
                              style={{
                                fontFamily: fontFamilyValue?.fontFamilyName,
                              }}
                            >
                              {obj}
                            </p>
                          </div>
                        );
                      })}
                      <p
                        style={{
                          fontFamily: fontFamilyValue?.fontFamilyName,
                        }}
                      >
                        0 1 2 3 4 5 6 7 8 9
                      </p>
                    </React.Fragment>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <div
            className={`${
              isEmpty(categoryFilter) ? "border_none_main" : "border_main"
            }  ${"typography_section_main"}`}
            id="color"
          >
            <h5 className={"typography_title"}>Typography</h5>
            <p className={"typography_details_main"}>
              This website uses a color palette of{" "}
              <span className={"color_counter"}>
                {filterGetColor(filterData?.color)?.slice(0, 7)?.length}
              </span>{" "}
              colors
            </p>
            <div className={"color_card_main"}>
              {isLoading ? (
                <Skeleton.Input active className={"color_card__skeleton"} />
              ) : (
                <React.Fragment>
                  {filterGetColor(filterData?.color)
                    ?.slice(0, 7)
                    ?.map((obj) => {
                      return (
                        <div
                          key={obj}
                          className={"color_card"}
                          style={{
                            backgroundColor: obj,
                          }}
                        >
                          <p className={"color_card_code"}>HEX {obj}</p>
                          <h1 className="color-abcd">Abcd</h1>
                        </div>
                      );
                    })}
                </React.Fragment>
              )}
            </div>
          </div>
          {/* <div className={"font_style_color_wrap"}></div> */}
          {!isEmpty(categoryFilter) && (
            <div className={"modal_owl_carousel_wrap"} id="exploreMore">
              <div className={"modal_owl_carousel_main"}>
                <div>
                  <p className={"carousel_title"}>Collections</p>
                </div>
                <div>
                  <h2 className={"carousel_details"}>
                    View more related collections
                  </h2>
                </div>
              </div>
              <div className={isLoading ? "category_carousel_skeleton" : ""}>
                {isLoading
                  ? Array.from({ length: 3 }, (_, i) => (
                      <Skeleton.Image
                        className={"drawer_card_skeleton"}
                        active
                        key={i}
                      />
                    ))
                  : !isLink &&
                    !isEmpty(categoryFilter) && (
                      <WebsiteCardCarouselView
                        {...{
                          scrollToTop,
                          slidesToShowImage:
                            categoryFilter?.length < 2
                              ? 1
                              : categoryFilter?.length === 2
                                ? 2
                                : 3,
                          websiteCardData: categoryFilter,
                          handleDrawerOpen,
                          handleWebsiteCardLike,
                          handleWebsiteViewCount,
                          handleWebsiteCardCollection,
                          token,
                          setDrawer,
                        }}
                      />
                    )}
              </div>
            </div>
          )}
          <div className={"modal_filter_menu_wrap"}>
            <div className={"modal_filter_menu_main"}>
              <div className={"filter_menu_logo_main"}>
                <Link
                  href={"/"}
                  onClick={() => setDrawer({ isModalOpen: false })}
                >
                  <Image src={whiteLogo} alt={""} className={"logo"} />
                </Link>
              </div>
              <div className={"modal_option_main"}>
                <div className={"together_title_wrap"}>
                  <p className={"together_title"}>{filterData?.agencyName}</p>
                </div>
                <div className={"menu_list_main"}>
                  <a href="#creator" className={"menu_list"}>
                    Creator
                  </a>
                  <a href="#fontFamily" className={"menu_list"}>
                    Font Family
                  </a>
                  <a href="#color" className={"menu_list"}>
                    Color
                  </a>
                  {!isLink && !isEmpty(categoryFilter) && (
                    <a href="#exploreMore" className={"menu_list"}>
                      Explore More
                    </a>
                  )}
                </div>
              </div>
              <div className={"visit_site_btn_main"}>
                {isLoading ? (
                  <p
                    disabled={true}
                    className={`${isLoading ? "isload-disable" : ""} visit_site_btn`}
                  >
                    Visit Site
                  </p>
                ) : (
                  <Link
                    href={
                      filterData
                        ? `${filterData?.websiteUrl}/${apiConst.REF_URL}`
                        : "/"
                    }
                    onClick={(e) =>
                      handleWebsiteViewCount(e, filterData?.websiteId)
                    }
                    target="_blank"
                    className={"visit_site_btn"}
                  >
                    Visit Site
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isAuthModal && <AuthenticationContainer />}
      <Modal
        closeIcon={false}
        open={isModalOpen}
        onCancel={handleCancelShareIconModal}
        footer={false}
        className={`${"social_media_icon_wrap"} ${
          isLink && "social_media_icon_wrap_new_tab"
        }`}
        placement="bottom"
      >
        <TwitterShareButton url={url} className={"social_media_icon_btn"}>
          <ImageComponent
            imageSrc={twitter}
            imageAlt="twitter"
            imageClassName={"social_media_icon"}
          />
        </TwitterShareButton>

        <LinkedinShareButton url={url} className={"linked_in_icon_btn"}>
          <ImageComponent
            imageSrc={linkedin}
            imageAlt="linkedin"
            imageClassName={"linked_in_icon"}
          />
        </LinkedinShareButton>

        <FacebookShareButton url={url} className={"social_media_icon_btn"}>
          <ImageComponent
            imageSrc={facebook}
            imageAlt="facebook"
            imageClassName={"social_media_icon"}
          />
        </FacebookShareButton>

        <WhatsappShareButton url={url} className={"social_media_icon_btn"}>
          <ImageComponent
            imageSrc={whatsapp}
            imageAlt="whatsapp"
            imageClassName={"social_media_icon"}
          />
        </WhatsappShareButton>
        <div className="social_media_icon_copy">
          <TooltipComponent
            {...{
              tooltipName: isCopiedUrl ? "Copied!" : "Click to Copy",
            }}
          />
          <Image
            src={copyBold}
            alt="copyBold"
            className={"social_media_icon"}
            onClick={() => handleCopyText(filterData?.websiteId, "copyURL")}
          />
        </div>
        <ImageComponent
          imageSrc={closes}
          imageAlt="close"
          imageClassName={"social_media_close_icon"}
          handleClick={handleCancelShareIconModal}
          imageHeight={20}
          imageWidth={20}
        />
      </Modal>
    </div>
  );
};

export default CardDetailsPageView;
