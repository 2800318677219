import React from "react";
import dynamic from "next/dynamic";
const Lottie = dynamic(() => import("react-lottie-player"));

const LottieComponent = ({ lottieImageData, lottieClassName }) => {
  return (
    <Lottie
      loop
      animationData={lottieImageData}
      play
      className={lottieClassName}
    />
  );
};

export default LottieComponent;
