import React from "react";
import "../../../../styles/like/like.scss";
import { ImageComponent } from "..";
import { likeCelebration } from "../../../../public/assets";
import TooltipComponent from "../Tooltip/TooltipComponent";
import { CountConvert } from "@/src/utils";

const LikeView = ({
  isLikeCelebration,
  handleWebsiteCardLike,
  obj,
  audioRef,
  isDetailsPage,
}) => {
  return (
    <div className="like-main">
      <div
        className={`${obj?.isLike ? "like" : "like-button-main"}
            `}
      >
        <audio ref={audioRef}>
          <source src="/like_sound.mp3" type="audio/mpeg" />
        </audio>
        {isLikeCelebration && (
          <ImageComponent
            imageSrc={likeCelebration}
            imageAlt="like-celebration"
            imageClassName={`${"like-celebration"} ${
              isLikeCelebration?.isCelebration &&
              obj?.websiteId === isLikeCelebration?.id
                ? "d-block"
                : "d-none"
            }`}
          />
        )}
        <svg
          onClick={() =>
            handleWebsiteCardLike(
              obj?.isLike,
              obj?.likeCount,
              obj?.websiteId,
              obj?.status,
              obj
            )
          }
          width="18"
          height="16"
          viewBox="0 0 18 16"
          fill="red"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.02573 14.4741L8.025 14.4734C5.6972 12.4284 3.80635 10.7663 2.49142 9.20879C1.18171 7.65747 0.5 6.27411 0.5 4.79564C0.5 2.40104 2.43909 0.5 4.95 0.5C6.37043 0.5 7.7372 1.14302 8.62445 2.15244L9 2.5797L9.37555 2.15244C10.2628 1.14302 11.6296 0.5 13.05 0.5C15.5609 0.5 17.5 2.40104 17.5 4.79564C17.5 6.27412 16.8183 7.6575 15.5085 9.21C14.1949 10.7669 12.3069 12.4291 9.98268 14.4754L9.97569 14.4815L9.9746 14.4825L9.00126 15.3344L8.02573 14.4741Z"
            // fill="#9E9EA7"
            // stroke="#9E9EA7"
          />
        </svg>
        <TooltipComponent
          {...{
            tooltipName: "Likes",
          }}
        />
        {!isDetailsPage && (
          <p className={"like-counter"}>{CountConvert(obj?.likeCount)}</p>
        )}
      </div>
    </div>
  );
};

export default LikeView;
