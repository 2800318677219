import React from "react";
import Link from "next/link";
import "../../../../styles/footer/footer.scss";
import { InstagramOutlined, LinkedinOutlined } from "@ant-design/icons";
import Image from "next/image";
import { HEAD_INSTAGRAM_LINK } from "@/src/const/head";
import { twitter, whiteLogo } from "../../../../public/assets";

const FooterComponent = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return (
    <footer className={"footer"}>
      <div className={"footer-div"}>
        <div className={"list-main"}>
          <div className={"title-div"}>
            <Link href={"/"}>
              <Image
                src={whiteLogo}
                alt={""}
                className={"logo"}
              />
            </Link>
          </div>
          <div className={"footer-menus-list"}>
            <Link className={"footer-menu"} href={"/terms"}>
              Terms
            </Link>
            {/* <Link className={footer-menu} href={"/advertise"}>
              Advertisement
            </Link> */}
            <Link className={"footer-menu"} href={"/privacy"}>
              Privacy
            </Link>
            <Link className={"footer-menu"} href={"/about"}>
              About us
            </Link>
            <Link className={"footer-menu"} href={"/support"}>
              Support
            </Link>
          </div>
          <div className={"social-media"}>
            <div className={"social-media-icon-main"}>
              <Image
                src={twitter}
                alt="arrow"
                className={`${"social-media-icon"} ${"twitter-icon"}`}
                height={19}
                width={19}
              />
            </div>
            <div className={"social-media-icon-main"}>
              <LinkedinOutlined
                className={`${"social-media-icon"} ${"linkedin-icon"}`}
              />
            </div>
            <div className={"social-media-icon-main"}>
              <Link href={HEAD_INSTAGRAM_LINK} target="_blank">
                <InstagramOutlined
                  className={`${"social-media-icon"} ${"linkedin-icon"}`}
                />
              </Link>
            </div>
            {/* <div className={social-media-icon-main}>
              <WhatsAppOutlined
                className={`${social-media-icon} ${linkedin-icon}`}
              />
            </div> */}
          </div>
        </div>
        <div>
          <p className={"copy_right_details"}>
            @{currentYear} uxvortex. All rights are reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
