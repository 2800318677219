"use client";
import { Checkbox } from "antd";
import React from "react";
import "../../../../styles/checkBox/checkBox.scss";

const CheckBoxComponent = ({
  obj,
  value,
  name,
  submitWebsiteData,
  handleCheckBoxChange,
  isSubmitData,
}) => {
  return (
    <div className={"checkbox_main"}>
      <div className={"list-main"}>
        <div
          className={`${
            name === "fonts" ? "font_style_select_tag" : ""
          } ${"industry-styles-checkbox-main"} ${
            submitWebsiteData?.[name]?.includes(value)
              ? "checked_fonts"
              : "remove_value"
          }`}
        >
          <Checkbox
            onChange={(e) => handleCheckBoxChange(e, value, name)}
            className={`
            ${"check_box_main"}`}
            disabled={isSubmitData ? true : obj?.isDisabled && !obj?.isSelected}
          >
            {value}
          </Checkbox>
        </div>
      </div>
    </div>
  );
};

export default CheckBoxComponent;
