export const CountConvert = (count) => {
  if (count < 1000) {
    return count;
  } else if (count >= 1000 && count <= 1000000) {
    const totalCount = count / 1000;
    return `${totalCount}K`;
  } else if (count >= 1000000) {
    const totalCount = count / 1000000;
    return `${totalCount?.toFixed(2)}M`;
  }
};
