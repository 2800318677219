"use client";
import React, { useEffect, useLayoutEffect, useState } from "react";
import WebsiteCardContainer from "../WebsiteCards/WebsiteCardContainer";
import {
  PortfolioWebsites,
  getUserWebsiteDetails,
  isAllWebsiteEndPage,
  isLoadingWebsite,
  isMyWebsiteEndPage,
  isPortfolioWebsitesEndPage,
  websiteSearchData,
  websites,
} from "@/src/redux/reducers/slices";
import { useDispatch, useSelector } from "react-redux";
import "../../../../styles/card/card.scss";
import { isEmpty } from "@/src/utils";
import { ButtonComponent } from "..";
import { TWELVE_COUNT } from "@/src/const/primitive";

const LoadMoreComponent = ({
  token,
  payload,
  data,
  getWebsiteData,
  handleUserWebsiteEdit,
  handleUserWebsiteDelete,
  isCarousel,
  isSearch,
  isMyWebsite,
  isMyPortfolio,
  userNameParams,
}) => {
  const [webData, setWebData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const { isMyWebsiteLastPage, userWebsiteData, userFilterWebData } =
    useSelector((state) => state.profile);
  const {
    isWebsiteAllDataLastPage,
    websiteData,
    searchRec,
    isLoadingWebsiteState,
  } = useSelector((state) => state.website);
  const { portfolioWebsitesData, portfolioWebsitesIsEndPage } = useSelector(
    (state) => state.userPortfolio
  );
  const isEndPage = isMyWebsite
    ? isMyWebsiteLastPage
    : isMyPortfolio
      ? portfolioWebsitesIsEndPage
      : isWebsiteAllDataLastPage;
  let debounceTimer = null;

  const loadMoreBeers = async (nextPage) => {
    if (isLoadingWebsiteState) return;
    dispatch(isLoadingWebsite(true));
    if (!isEndPage) {
      let payloadData = {
        ...payload,
        page: nextPage,
        limit: TWELVE_COUNT,
      };
      let newProducts;
      if (isMyPortfolio) {
        const params = userNameParams;
        newProducts = await dispatch(getWebsiteData(params, payloadData));
      } else {
        newProducts = await getWebsiteData(payloadData);
      }
      let SearchData;
      let filterData;
      if (isMyWebsite) {
        const data = [...(userWebsiteData || []), ...(newProducts?.data || [])];
        filterData = data;
        await dispatch(getUserWebsiteDetails(data));
        await dispatch(isMyWebsiteEndPage(newProducts?.isEnd));
      } else if (isSearch) {
        SearchData = [...(searchRec || []), ...(newProducts?.data || [])];
        filterData = SearchData;
        await dispatch(websiteSearchData(SearchData));
      } else if (isMyPortfolio) {
        SearchData = [
          ...(portfolioWebsitesData || []),
          ...(newProducts?.allWebsites || []),
        ];
        filterData = SearchData;
        await dispatch(PortfolioWebsites(SearchData));
        await dispatch(isPortfolioWebsitesEndPage(newProducts?.isEnd));
      } else {
        const data = [...(websiteData || []), ...(newProducts?.data || [])];
        filterData = data;
        await dispatch(websites(data));
        await dispatch(isAllWebsiteEndPage(newProducts?.isEnd));
      }
      setWebData(filterData);
    }
    await dispatch(isLoadingWebsite(false));
  };
  const handleScrollPage = () => {
    if (isLoadingWebsiteState) return;
    if (Boolean(currentPage % 5)) {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
      debounceTimer = setTimeout(async () => {
        if (
          window.innerHeight + document.documentElement.scrollTop + 1 >=
          document.documentElement.scrollHeight
        ) {
          if (!isWebsiteAllDataLastPage) {
            await loadMoreBeers(currentPage + 1);
            await dispatch(isLoadingWebsite(false));
            setCurrentPage((prev) => prev + 1);
          }
        }
      }, 200);
    }
  };
  const handleOnScrollPaginationButton = async () => {
    if (!isWebsiteAllDataLastPage) {
      await loadMoreBeers(currentPage + 1);
    }
    setCurrentPage((prev) => prev + 1);
  };
  useLayoutEffect(() => {
    setWebData(data);
  }, [data]);
  
  useEffect(() => {
    if (
      !isEmpty(websiteData) ||
      !isEmpty(searchRec) ||
      !isEmpty(userWebsiteData) ||
      !isEmpty(userFilterWebData) ||
      !isEmpty(portfolioWebsitesData)
    ) {
      window.addEventListener("scroll", handleScrollPage);
      return () => {
        window.removeEventListener("scroll", handleScrollPage);
        if (debounceTimer) {
          clearTimeout(debounceTimer);
        }
      };
    }
  }
  // , [
  //   currentPage,
  //   websiteData?.length,
  //   searchRec,
  //   data,
  //   isWebsiteAllDataLastPage,
  //   userWebsiteData,
  //   userFilterWebData,
  //   portfolioWebsitesData,
  //   window,
  //   debounceTimer
  // ]
);
  return (
    <React.Fragment>
      <WebsiteCardContainer
        {...{
          data: !isEmpty(webData) ? Array.isArray(webData) ? webData : [webData] : webData,
          isCarousel,
          token,
          isMyWebsite,
          handleUserWebsiteEdit,
          handleUserWebsiteDelete,
          isMyPortfolio,
        }}
      />
      {!isEmpty(webData) && isEndPage && (
        <p className="end-page-massage">You’ve reached the end of the list</p>
      )}
      {currentPage % 5 === 0 && !isEndPage && (
        <ButtonComponent
          type={"button"}
          handleClick={() => handleOnScrollPaginationButton()}
          classNames={"load-btn"}
          btnText={"Load More"}
        />
      )}
    </React.Fragment>
  );
};

export default LoadMoreComponent;
