import { apiConst } from "@/src/const/apiConst";
import {
  post,
  remove,
  removeAll,
} from "@/src/utils/axiosInterceptor/AxiosInterceptor";
import { openNotification } from "@/src/utils";
import { deleteCollection, manageCollections } from "../../reducers/slices";
import { getCollectionData } from "../getApis/GetApis";

export const addCollectionData = (payload) => {
  return async (dispatch) => {
    try {
      const data = await post(`${apiConst.ADD_COLLECTION}`, payload);
      if (data) {
        const dataCollection = await getCollectionData();
        dispatch(manageCollections(dataCollection));
        if (data?.status === 200) {
          return data;
        }
      } else {
        openNotification("Website is already exist in collection", "warning");
      }
    } catch (error) {
      return error;
    }
  };
};

export const removeCollectionById = (id) => {
  return async (dispatch) => {
    try {
      const data = await remove(`${apiConst.DELETE_COLLECTION(id)}`);
      if (data) {
        const res = await getCollectionData();
        dispatch(manageCollections(res));
        return res;
      }
    } catch (err) {
      return err;
    }
  };
};

export const removeCollection = (CollectionName) => {
  return async (dispatch) => {
    try {
      const data = await removeAll(`${apiConst.COLLECTION_DELETE}`, {
        collectionsName: CollectionName,
      });
      if (data) {
        dispatch(deleteCollection(CollectionName));
      }
    } catch (err) {
      return err;
    }
  };
};
