import React from "react";
import { CardComponent, NoDataFound } from "..";
import { Col, Skeleton } from "antd";
import { isEmpty } from "@/src/utils";

const WebsiteCardView = (props) => {
  const {
    manageCollection,
    token,
    websiteCardData = [],
    drawer,
    modal,
    setModal,
    setWebsiteCardData,
    setDrawer,
    isMyWebsite,
    collectionError,
    handleUserWebsiteEdit,
    handleUserWebsiteDelete,
    handleDrawerOpen,
    handleSaveCollection,
    handleAddCollectionName,
    isLoadingWebsiteState,
    handleWebsiteViewCount,
    handleOnMouseHover,
    userOnHoverFilterData,
    userNameApplied,
    isCarousel,
    handleOnMouseLeave,
    isMyPortfolio,
    userPortfolioProfileData,
    isPortfolioWebsite,
  } = props;
  return (
    <React.Fragment>
      {!isEmpty(websiteCardData) &&
        websiteCardData?.map((websiteDataObj) => (
          <Col
            key={websiteDataObj.websiteId}
            span={24}
            xxl={6}
            xl={6}
            lg={8}
            md={12}
            sm={24}
            xs={24}
          >
            <CardComponent
              {...{
                websiteDataObj,
                isMyWebsite,
                manageCollection,
                handleUserWebsiteEdit,
                handleUserWebsiteDelete,
                token,
                drawer,
                collectionError,
                data: websiteCardData,
                modal,
                setModal,
                setWebsiteCardData,
                setDrawer,
                handleDrawerOpen,
                handleSaveCollection,
                handleAddCollectionName,
                handleWebsiteViewCount,
                handleOnMouseHover,
                userOnHoverFilterData,
                userNameApplied,
                isCarousel,
                handleOnMouseLeave,
                isMyPortfolio,
                userPortfolioProfileData,
                isPortfolioWebsite,
              }}
            />
          </Col>
        ))}
      {isLoadingWebsiteState
        ? Array.from({ length: 8 }, (_, i) => (
            <Col
              key={i}
              className="skeleton-wrap"
              span={24}
              xxl={6}
              xl={6}
              lg={8}
              md={12}
              sm={24}
              xs={24}
            >
              <Skeleton.Input active className="skeleton-main" />
            </Col>
          ))
        : !isLoadingWebsiteState && isEmpty(websiteCardData) && <NoDataFound />}
    </React.Fragment>
  );
};

export default WebsiteCardView;
