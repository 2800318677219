"use client";
import React from "react";
import CollectionView from "./CollectionView";
import { removeCollectionById } from "@/src/redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNav } from "../context/NavbarProvider";
import {
  PortfolioCollectionUpdate,
  categoryCarouselCollection,
  collectionDataUpdateTopTen,
  saveDataFilterUpdate,
  websiteCollectionUpdate,
} from "@/src/redux/reducers/slices";

const CollectionContainer = ({
  manageCollection,
  token,
  obj,
  data,
  setWebsiteCardData,
  isDetailsPage,
  drawer,
  modal,
  collectionError,
  setModal,
  setDrawer,
  handleSaveCollection,
  handleAddCollectionName,
}) => {
  const { profileData } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const { setIsAuthModal } = useNav();
  const handleScrollTop = () => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  };

  const handleWebsiteCardCollection = async (e, isCollection, id, img, alt) => {
    e.stopPropagation();
    if (token) {
      if (profileData?.auth?.isVerified) {
        const isInCollections = !isCollection;
        if (isInCollections) {
          setModal({
            ...modal,
            id: id,
            isAddCollection: true,
            image: img,
            imgAlt: alt,
            isCreateCollection: false,
          });
        } else {
          let updatedData = {
            id: id,
            isCollection: false,
          };

          const allUpdatedDataWebsite = data?.map((obj) => {
            if (obj?.websiteId === updatedData?.id) {
              let a = {
                ...obj,
                isCollection: updatedData?.isCollection,
              };
              return a;
            } else {
              return obj;
            }
          });
          const updatedScreenshotDetails = {
            ...obj,
            isCollection: updatedData?.isCollection,
          };
          setDrawer({
            ...drawer,
            data: updatedScreenshotDetails,
            isOpen: false,
          });
          setWebsiteCardData(allUpdatedDataWebsite || []);
          dispatch(PortfolioCollectionUpdate(updatedData));
          dispatch(websiteCollectionUpdate(updatedData));
          dispatch(collectionDataUpdateTopTen(updatedData));
          dispatch(categoryCarouselCollection(updatedData));
          dispatch(saveDataFilterUpdate(updatedScreenshotDetails));
          dispatch(removeCollectionById(id));
        }
      } else {
        handleScrollTop();
      }
    } else {
      setIsAuthModal(true);
    }
  };

  const handleCancelModel = () => {
    setModal({
      ...modal,
      isAddCollection: false,
      isCreateCollection: false,
    });
  };
  return (
    <CollectionView
      {...{
        isDetailsPage,
        collectionError,
        obj,
        modal,
        manageCollection,
        handleCancelModel,
        handleSaveCollection,
        handleAddCollectionName,
        handleWebsiteCardCollection,
      }}
    />
  );
};

export default CollectionContainer;
