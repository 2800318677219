import { apiConst } from "@/src/const/apiConst";
import { AUTH_TOKEN } from "@/src/const/primitive";
import { postWithoutToken } from "@/src/utils/axiosInterceptor/AxiosInterceptor";
import { token } from "../../reducers/slices";
import { create } from "@/src/app/actions";

export const signUpUser = (email, password, userName) => {
  return async () => {
    const authObj = {
      emailId: email,
      password,
      userName,
    };
    const response = await postWithoutToken(
      `${apiConst.USER_REGISTER_WITH_GOOGLE}`,
      authObj
    );
    return response;
  };
};

export const loginUser = (email, password, coords) => {
  return async (dispatch) => {
    const authObj = {
      emailId: email,
      password,
    };
    const extraHeaders = {
      "user-agent": navigator.userAgent,
      "x-location": JSON.stringify(coords),
    };
    const response = await postWithoutToken(
      `${apiConst.USER_LOGIN}`,
      authObj,
      {},
      extraHeaders
    );
    create(response?.data?.token);
    localStorage?.setItem(AUTH_TOKEN, response?.data?.token);
    if (response?.status === 200) {
      dispatch(token(response?.data?.token));
      return response;
    }
    return response;
  };
};

export const loginWithGoogle = (accessToken, coords) => {
  return async () => {
    const payload = { googleAccessToken: accessToken };
    const extraHeaders = {
      "user-agent": navigator.userAgent,
      "x-location": JSON.stringify(coords),
    };
    const response = await postWithoutToken(
      `${apiConst.USER_LOGIN_WITH_GOOGLE}`,
      payload,
      {},
      extraHeaders
    );
    create(response?.data?.token);
    localStorage?.setItem(AUTH_TOKEN, response?.data?.token);
    return { status: 200, data: response?.data };
    // if (response.status === 200) {
    //   router?.push("/");
    // }
  };
};
