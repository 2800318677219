export const formattedDate = (epochTime) => {
  const date = new Date(epochTime);
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  return formattedDate;
};

export const normalDateTime = (epochTime) => {
  const normalTime = new Date(epochTime * 1000);
  const MONTH = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const year = normalTime.getFullYear();
  const month = MONTH[normalTime.getMonth()];
  const day = normalTime.getDate();
  const hours = normalTime.getHours();
  const minutes = normalTime.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const hours12 = hours % 12 || 12;
  return `${day < 10 ? `0${day}` : day}-${month}-${year}  ${hours12 < 10 ? `0${hours12}` : hours12}:${
    minutes < 10 ? `0${minutes}` : minutes
  }  ${ampm}`;
};

export const convertEpochTimeToNormalTime = (epochTime) => {
  const date = new Date(epochTime * 1000);
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const period = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  return `${formattedHours < 10 ? `0${formattedHours}` : formattedHours}:${minutes < 10 ? `0${minutes}` : minutes} ${period}`;
};

export const getTimeAgo = (date) => {
  date = new Date(date);
  const timeAgo = Date.now() - date.getTime();
  const seconds = Math.floor(timeAgo / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);
  if (years > 0) {
    return `${years} year${years === 1 ? "" : "s"} ago`;
  } else if (months > 0) {
    return `${months} month${months === 1 ? "" : "s"} ago`;
  } else if (weeks > 0) {
    return `${weeks} week${weeks === 1 ? "" : "s"} ago`;
  } else if (days > 0) {
    return `${days} day${days === 1 ? "" : "s"} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours === 1 ? "" : "s"} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
  } else {
    return `${seconds} second${seconds === 1 ? "" : "s"} ago`;
  }
};

export const getDate = (dateString) => {
  const date = new Date(dateString);
  const options = { month: "long", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
};
