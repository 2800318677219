"use client";
import React, { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { ButtonComponent, SuccessModal } from "..";
import { resendVerifyConfirmation } from "@/src/redux/actions";
import "../../../../styles/navbar/navbar.scss";

const UserVerified = ({ email, isVerified }) => {
  const [isEmailSendModal, setIsEmailSendModal] = useState(false);
  const [isStatus, setIsStatus] = useState(false);

  const handleResendConfirmation = async () => {
    setIsStatus(true);
    const response = await resendVerifyConfirmation(email);
    setIsStatus(false);
    if (response === 200) {
      setIsEmailSendModal(true);
    }
  };
  return (
    <div className={"email-verify-notice-main"}>
      {isVerified === false && (
        <p className={"email-verify-text"}>
          Verify your email to get the most out of UxVortex. Didn&apos;t receive
          an email?
          <ButtonComponent
            btnText={isStatus ? <LoadingOutlined /> : "Resend confirmation"}
            handleClick={handleResendConfirmation}
            disabled={isStatus && true}
            classNames={"confirm-button"}
          />
        </p>
      )}

      {isEmailSendModal && (
        <SuccessModal
          {...{
            isSuccessModal: isEmailSendModal,
            setIsSuccessModal: setIsEmailSendModal,
            descriptionText: "You should receive an email",
            isEmailSend: isEmailSendModal && true,
          }}
        />
      )}
    </div>
  );
};

export default UserVerified;
