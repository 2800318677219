import { Button } from "antd";
import "../../../../styles/buttonComponent/buttonComponent.scss";

const ButtonComponent = (props) => {
  const { btnText, classNames, disabled, handleClick, type, htmlType } = props;
  return (
    <Button
      className={`${classNames} common-button`}
      onClick={handleClick}
      disabled={disabled}
      type={type}
      htmlType={htmlType || "button"}
    >
      {btnText}
    </Button>
  );
};

export default ButtonComponent;
