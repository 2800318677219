import {
  ButtonComponent,
  ImageComponent,
  InputComponent,
  SuccessModal,
} from "@/src/components/CommonComponents";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Col, Form, Modal, Row } from "antd";
import React from "react";
import "../../../../../../styles/signIn/auth.scss";
import { AUTHENTICATION_FIELD } from "@/src/const/nonPrimitive";
import { isEmpty } from "@/src/utils";
import {
  authImage,
  google,
  password,
  userNameIcon,
} from "../../../../../../public/assets";
import dynamic from "next/dynamic";
const Lottie = dynamic(() => import("react-lottie-player"));

const AuthenticationView = ({
  isWarningModal,
  isStatus,
  inputRef,
  isAuthModal,
  isSignUp,
  authDetails,
  loginError,
  setIsWarningModal,
  handleCancelAuthModal,
  handleClickSignIn,
  handleInputChange,
  handleAuthKeyDown,
  handleAuthBlur,
  handleSubmitLoginData,
  handleForgotPassword,
  handleGoogleLogin,
  isEmailSendModal,
  setIsEmailSendModal,
  isPasswordShow,
  handleIsShowPassword,
  handleIsShowPasswordInvisible,
}) => {
  return (
    <React.Fragment>
      <Modal
        className={"modal-sign-in"}
        open={isAuthModal}
        onCancel={handleCancelAuthModal}
        footer={false}
        closeIcon={false}
        width={"auto"}
        zIndex={2080}
        centered  
      >
        <div className={"log-in-form"}>
          <Row>
            <Col
              span={24}
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              className={"log-in-form-left"}
            >
              <Lottie
                loop
                animationData={authImage}
                play
                className={"sign-in-page-image"}
              />
            </Col>
            <Col
              span={24}
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              className={"log-in-form-right"}
            >
              <div className={"form"}>
                <div className={"switch-form-main"}>
                  <ButtonComponent
                    btnText={"Sign In"}
                    handleClick={handleClickSignIn}
                    classNames={
                      isSignUp
                        ? "sign-up-button-default"
                        : `${"sign-in-button-active"}`
                    }
                  />
                  <ButtonComponent
                    btnText={"Sign Up"}
                    handleClick={handleClickSignIn}
                    classNames={
                      isSignUp
                        ? "sign-up-button-active"
                        : "sign-up-button-default"
                    }
                  />
                </div>
                <div className={"form-wrap"}>
                  <h2 className={"form-title"}>
                    {isSignUp ? "Register Now" : "Sign in now"}
                  </h2>
                  <Form className={"main-div"}>
                    <div className={"log-in-form-main"}>
                      {isSignUp && (
                        <Form.Item className={"main-input"}>
                          <div className={"border"}>
                            <ImageComponent
                              imageSrc={userNameIcon}
                              imageAlt="confirm-password"
                              imageClassName={"profile-user-image"}
                              imageWidth={17}
                              imageHeight={19}
                            />
                          </div>
                          <label className={"email-label"}>{"User Name"}</label>
                          <InputComponent
                            classNames={"email-input"}
                            name="userName"
                            type={"text"}
                            handleKeyDown={handleAuthKeyDown}
                            placeholder="Enter User Name"
                            handleChange={(e) => handleInputChange(e, 0, 31)}
                            value={authDetails?.userName}
                            error={loginError?.userName}
                            maxLength={30}
                            handleBlur={() => handleAuthBlur(30, "userName")}
                            inputRef={
                              isEmpty(authDetails?.userName) ||
                              !isEmpty(loginError?.userName)
                                ? inputRef
                                : null
                            }
                          />
                        </Form.Item>
                      )}
                      {AUTHENTICATION_FIELD?.map((obj) => {
                        return (
                          <Form.Item key={obj?.label} className={"main-input"}>
                            <label className={"email-label"}>
                              {obj?.label}
                            </label>
                            <div className={"border"}>
                              <ImageComponent
                                imageSrc={obj?.image}
                                imageAlt={obj?.label}
                                imageClassName={
                                  obj?.name === "email"
                                    ? "email-image"
                                    : "password-image"
                                }
                              />
                            </div>
                            <InputComponent
                              classNames={"email-input"}
                              name={obj?.name}
                              type={
                                isPasswordShow[obj?.name] ? "text" : obj?.type
                              }
                              value={authDetails?.[obj?.name]}
                              error={
                                loginError?.auth
                                  ? obj?.name === "password" && loginError?.auth
                                  : loginError?.[obj?.name]
                              }
                              handleKeyDown={handleAuthKeyDown}
                              placeholder={obj?.placeholder}
                              maxLength={obj?.maxLength}
                              minLength={obj?.minLength}
                              handleChange={(e) =>
                                handleInputChange(e, 0, obj?.maxLength)
                              }
                              inputRef={
                                isEmpty(authDetails?.[obj?.name]) ||
                                !isEmpty(loginError?.[obj?.name])
                                  ? inputRef
                                  : null
                              }
                              handleBlur={() =>
                                handleAuthBlur(obj?.maxLength - 1, obj?.name)
                              }
                            />
                            {obj?.name === "password" &&
                              (isPasswordShow[obj?.name] ? (
                                <EyeOutlined
                                  onClick={() =>
                                    handleIsShowPasswordInvisible(obj?.name)
                                  }
                                  className={"eye-icons"}
                                />
                              ) : (
                                <EyeInvisibleOutlined
                                  onClick={() =>
                                    handleIsShowPassword(obj?.name)
                                  }
                                  className={"eye-icons"}
                                />
                              ))}
                          </Form.Item>
                        );
                      })}
                      {isSignUp && (
                        <Form.Item className={"main-input"}>
                          <div className={"border"}>
                            <ImageComponent
                              imageSrc={password}
                              imageAlt="confirm-password"
                              imageClassName={"password-image"}
                            />
                          </div>
                          <label className={"email-label"}>
                            {"Confirm password"}
                          </label>
                          <InputComponent
                            classNames={"email-input"}
                            name="confirmPassword"
                            type={
                              isPasswordShow["confirm-password"]
                                ? "text"
                                : "password"
                            }
                            handleKeyDown={handleAuthKeyDown}
                            placeholder="Enter confirm password"
                            handleChange={(e) => handleInputChange(e, 0, 500)}
                            value={authDetails?.confirmPassword}
                            error={loginError?.confirmPassword}
                            maxLength={500}
                            handleBlur={() =>
                              handleAuthBlur(501 - 1, "confirmPassword")
                            }
                            inputRef={
                              isEmpty(authDetails?.confirmPassword) ||
                              !isEmpty(loginError?.confirmPassword)
                                ? inputRef
                                : null
                            }
                          />
                          {isPasswordShow["confirm-password"] ? (
                            <EyeOutlined
                              onClick={() =>
                                handleIsShowPasswordInvisible(
                                  "confirm-password"
                                )
                              }
                              className={"eye-icons"}
                            />
                          ) : (
                            <EyeInvisibleOutlined
                              onClick={() =>
                                handleIsShowPassword("confirm-password")
                              }
                              className={"eye-icons"}
                            />
                          )}
                        </Form.Item>
                      )}
                    </div>
                    {!isSignUp && (
                      <ButtonComponent
                        classNames={"forgot-password-button"}
                        btnText="Forgot password?"
                        handleClick={handleForgotPassword}
                      />
                    )}
                    <ButtonComponent
                      name={isSignUp ? "Sign Up" : "Sign In"}
                      classNames={"log-in-button"}
                      btnText={
                        isStatus ? (
                          <LoadingOutlined />
                        ) : isSignUp ? (
                          "Sign Up"
                        ) : (
                          "Sign In"
                        )
                      }
                      disabled={isStatus && true}
                      handleClick={!isStatus && handleSubmitLoginData}
                    />
                    <div className={"sign-in-with-google-main"}>
                      {/* <p className={"option-or-title"}> */}
                        {/* <hr className={"sing_in_hr"} />
                        <span className={"sign_in"}>
                          Or {isSignUp ? "Sign Up" : "Sign In"} with
                        </span>
                        <hr /> */}
                      {/* </p> */}
                      <p
                        onClick={handleGoogleLogin}
                        className={"sign-in-google"}
                      >
                        <ImageComponent
                          imageSrc={google}
                          imageAlt="google"
                          imageClassName={"google-image"}
                        />
                        {isSignUp ? "Sign Up" : "Sign In"} with Google
                      </p>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
      {(isWarningModal || isEmailSendModal) && (
        <SuccessModal
          {...{
            isSuccessModal: isWarningModal ? isWarningModal : isEmailSendModal,
            setIsSuccessModal: isWarningModal
              ? setIsWarningModal
              : setIsEmailSendModal,
            descriptionText: isWarningModal
              ? "User Already exists , Please try with new email"
              : "You should receive an email",
            isWarning: isWarningModal && true,
            isEmailSend: isEmailSendModal && true,
          }}
        />
      )}
    </React.Fragment>
  );
};

export default AuthenticationView;
