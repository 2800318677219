import { apiConst } from "@/src/const/apiConst";
import { post } from "@/src/utils/axiosInterceptor/AxiosInterceptor";
import {
  allNotificationsRec,
  notificationCountData,
  notificationsId,
} from "../../reducers/slices";

export const markAllAsRead = (payload) => {
  return async (dispatch) => {
    try {
      const response = await post(apiConst?.MARK_ALL_AS_READ, payload);
      if (response.status === 200) {
        dispatch(allNotificationsRec(response?.data?.data?.notifications));
        dispatch(notificationsId(response?.data?.data?.notificationId));
        dispatch(notificationCountData(response?.data?.data?.notifications));
        return response;
      }
    } catch (error) {
      return error;
    }
  };
};
