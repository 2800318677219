/* eslint-disable no-unused-expressions */
"use client";
import React from "react";
import "../../../../styles/userProfileImage/userImage.scss";
import { ImageComponent } from "..";
import { profileAvatar } from "../../../../public/assets";

const UserProfileImage = ({
  profileImage,
  firstName,
  lastName,
  handleProfileOnClick,
  classNames,
  ImageClassName,
  imgHeight,
  imgWidth,
  characterClassName,
}) => {
  const getCharacter = () => {
    let nameLetters;
    firstName && lastName
      ? (nameLetters =
          firstName?.charAt(0).toUpperCase() +
          lastName?.charAt(0).toUpperCase())
      : firstName
        ? (nameLetters = firstName?.charAt(0).toUpperCase())
        : lastName
          ? (nameLetters = lastName?.charAt(0).toUpperCase())
          : "";

    return nameLetters;
  };

  return (
    <React.Fragment>
      {profileImage ? (
        <div className={`${"user-profile-images"} ${classNames}`}>
          <ImageComponent
            imageSrc={profileImage}
            imageAlt={`${firstName} ${lastName}`}
            imageClassName={`${"dashboard-screenshot-image"} ${ImageClassName}`}
            imageHeight={imgHeight || 25}
            imageWidth={imgWidth || 25}
            imageSizes={`(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 33vw`}
            handleClick={handleProfileOnClick}
          />
        </div>
      ) : firstName || lastName ? (
        <div
          onClick={handleProfileOnClick}
          className={`${!classNames && "profile-name-character"} ${
            characterClassName ? characterClassName : ""
          }`}
        >
          {getCharacter()}
        </div>
      ) : (
        <ImageComponent
          imageHeight={imgHeight || 25}
          imageWidth={imgWidth || 25}
          imageSrc={profileAvatar}
          imageAlt="profile"
          imageClassName={ImageClassName}
          handleClick={handleProfileOnClick}
        />
      )}
    </React.Fragment>
  );
};

// UserProfileImage.defaultProps = {
//   handleProfileOnClick: () => {},
//   customClasses: "",
// };

export default UserProfileImage;
