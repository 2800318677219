import { jwtDecode } from "jwt-decode";
import isEmpty from "./isEmpty/isEmpty";

const isTokenValid = (token) => {
  if (!isEmpty(token)) {
    const decoded = jwtDecode(token);
    if (!decoded || Date.now() / 1000 > decoded?.exp) {
      return false;
    } else {
      return true;
    }
  } else {
    return token;
  }
};

export default isTokenValid;
