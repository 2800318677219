import { apiConst } from "@/src/const/apiConst";
import { openNotification } from "@/src/utils";
import {
  post,
  postWithoutToken,
} from "@/src/utils/axiosInterceptor/AxiosInterceptor";

export const userGetPortfolioDetail = (userName, payload) => {
  return async () => {
    const res = await postWithoutToken(
      `${apiConst.GET_USER_PORTFOLIO_WEBSITE_DETAILS(userName)}`,
      payload
    );
    return res?.data;
  };
};

export const userPostPortfolioGetInTouch = (userName, payload) => {
  return async () => {
    const res = await post(
      `${apiConst.POST_USER_PORTFOLIO_WEBSITE_DETAILS(userName)}`,
      payload
    );
    if (res?.status === 200) {
      openNotification(res?.data?.message, "success");
    }
    return res;
  };
};
