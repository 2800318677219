"use client";
import React from "react";
import "../../../../styles/noDataFound/noDataFound.scss";
import { noDataFound } from "../../../../public/assets";
import dynamic from "next/dynamic";
const Lottie = dynamic(() => import("react-lottie-player"));

const NoDataFound = ({ isCarousel, classNames }) => {
  return (
    <div className={"data_not_found_image_main"}>
      <Lottie
        loop
        animationData={noDataFound}
        play
        className={`${"data_not_found_image"} ${
          isCarousel ? "carousel_data_not_found" : ""
        } ${classNames}`}
      />
      <p className={"no_data_found_text"}>No Data Found</p>
    </div>
  );
};

export default NoDataFound;
