export const filterGetColor = (color) => {
  const filteredColor = color
    ?.split(",")
    ?.filter(
      (color) =>
        color?.toLowerCase() !== "#000000".toLowerCase() &&
        color?.toLowerCase() !== "#FFFFFF".toLowerCase()
    );
  return filteredColor;
};
