import { message, notification } from "antd";

const openNotification = (msg, status) => {
  if (status === "error") {
    message.open({
      type: "error",
      content: msg,
      duration: 2,
    });
  } else if (status === "warning") {
    notification["warning"]({
      message: msg,
      duration: 1,
    });
  } else {
    notification["success"]({
      message: msg,
      duration: 1,
    });
  }
};

export default openNotification;
