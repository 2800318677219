"use client";
import React from "react";
import { Select } from "antd";
import { isEmpty } from "@/src/utils";
const { Option } = Select;

const DropDownComponent = (props) => {
  const {
    options,
    suffixIcon,
    handleChange,
    defaultValue,
    classNames,
    name,
    placeholder,
    value,
    allowClear,
  } = props;
  return (
    <>
      <Select
        suffixIcon={suffixIcon}
        className={`${classNames}`}
        placeholder={placeholder}
        allowClear={allowClear}
        name={name}
        value={value}
        defaultValue={defaultValue}
        onChange={(e) => handleChange(name, e)}
      >
        {Array.isArray(options) &&
          !isEmpty(options) &&
          options?.map((obj) => {
            return (
              <Option value={obj?.value} key={obj?.label}>
                {obj?.label}
              </Option>
            );
          })}
      </Select>
    </>
  );
};

export default DropDownComponent;
