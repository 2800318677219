import { apiConst } from "@/src/const/apiConst";
import {
  updateWithFileFormData,
  remove,
  post,
  postWithoutToken,
} from "@/src/utils/axiosInterceptor/AxiosInterceptor";
import axios from "axios";
import {
  userUpdateProfileData,
  userWebsiteDeleteRecord,
  userWebsiteFilterUpdateRecord,
  userWebsiteUpdateRecord,
} from "../../reducers/slices";

export const verifyUserAccount = (profilePayload, token, email) => {
  return async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const res = await axios.put(
        `${apiConst.BASE_URL}${apiConst.VERIFY_ACCOUNT(token, email)}`,
        profilePayload,
        { headers }
      );
      if (res) {
        return res;
      }
    } catch (error) {
      return error;
    }
  };
};

export const updateUserProfileData = (formData, base64Image) => {
  return async (dispatch) => {
    try {
      const res = await updateWithFileFormData(
        `${apiConst.USER_PROFILE}`,
        formData
      );
      if (res?.status === 200) {
        const payloadState = {
          ...res?.data?.data,
          profileImg: base64Image
            ? URL?.createObjectURL(base64Image)
            : res?.data?.data?.profileImg,
        };
        dispatch(userUpdateProfileData(payloadState));
      }
      return res?.data;
    } catch (error) {
      return error;
    }
  };
};

export const deleteUserWebsiteData = (id) => {
  return async (dispatch) => {
    try {
      const res = await remove(`${apiConst.DELETE_WEBSITE_USER(id)}`);
      if (res) {
        dispatch(userWebsiteDeleteRecord(id));
        return res?.data?.data;
      }
    } catch (error) {
      return error;
    }
  };
};

export const updateUserWebsiteData = (id, payload) => {
  return async (dispatch) => {
    try {
      const res = await updateWithFileFormData(
        `${apiConst.UPDATE_WEBSITE_USER(id)}`,
        payload
      );
      if (res) {
        dispatch(userWebsiteUpdateRecord(res?.data?.data));
        dispatch(userWebsiteFilterUpdateRecord(res?.data?.data));
        return res;
      }
    } catch (error) {
      return error;
    }
  };
};

export const deleteUserAccount = () => {
  return async () => {
    try {
      const response = await post(apiConst?.DELETE_ACCOUNT);
      if (response?.status === 200) {
        return response;
      }
    } catch (error) {
      return error;
    }
  };
};
export const deleteUserImage = () => {
  return async () => {
    const response = await remove(apiConst.DELETE_PROFILE_IMAGE);
    return response?.status;
  };
};

export const userNameAlreadyExists = (payload) => {
  return async () => {
    const res = await postWithoutToken(
      `${apiConst.USERNAME_CHECK_EXIST}`,
      payload
    );
    if (res) {
      return res?.data;
    }
  };
};
