import { VALIDATION } from "@/src/const/nonPrimitive";
import {
  CONFIRM_PASSWORD_MATCH,
  RESET_PASSWORD_MATCH,
} from "@/src/const/primitive";
import isEmpty from "../isEmpty/isEmpty";

export const addWebsiteValidation = (
  name,
  addWebsite,
  webFormError,
  isSignUp
) => {
  let isValid = false;
  let errors = { ...webFormError };
  const fieldObj = VALIDATION[name];
  if (fieldObj?.required && isEmpty(addWebsite[name])) {
    errors[name] = fieldObj?.requiredMsg;
    isValid = true;
  } else if (
    fieldObj?.minLength &&
    addWebsite[name]?.length < fieldObj?.minLength
  ) {
    errors[name] = fieldObj?.lengthMsg;
    isValid = true;
  } else if (
    isSignUp &&
    fieldObj?.pattern &&
    !addWebsite[name]?.match(fieldObj?.pattern)
  ) {
    errors[name] = fieldObj?.validMsg;
    isValid = true;
  } else if (
    fieldObj?.maxLength &&
    addWebsite[name]?.length > fieldObj?.maxLength
  ) {
    errors[name] = fieldObj?.lengthMsg;
    isValid = true;
  } else if (isSignUp && (name === "confirmPassword" || name === "password")) {
    if (addWebsite?.password === addWebsite?.currentPassword) {
      if (!isEmpty(addWebsite?.confirmPassword)) {
        errors["confirmPassword"] = "";
      }
      errors["password"] = RESET_PASSWORD_MATCH;
      isValid = true;
    } else if (addWebsite?.password !== addWebsite?.confirmPassword) {
      errors["confirmPassword"] = CONFIRM_PASSWORD_MATCH;
      errors["password"] = "";
    } else {
      errors[name] = "";
      isValid = false;
    }
  } else {
    errors[name] = "";
    isValid = false;
  }
  return { errors, isValid };
};
