import { apiConst } from "@/src/const/apiConst";
import {
  patch,
  post,
  postWithoutToken,
  postWithFileFormData,
} from "@/src/utils/axiosInterceptor/AxiosInterceptor";
import { TOKEN } from "@/src/const/primitive";
import { categoryCarouselFilter } from "../../reducers/slices";
import axios from "axios";

export const websiteAlreadyExistsURL = (obj) => {
  return async () => {
    const res = await post(`${apiConst.WEBSITE_ALREADY_EXIST}`, obj);
    if (res) {
      return res?.data;
    }
  };
};
export const phoneNumberAlreadyExists = (obj) => {
  return async () => {
    const res = await post(`${apiConst.PHONE_NUMBER_ALREADY_EXIST}`, obj);
    if (res) {
      return res?.data;
    }
  };
};
export const updateFontsFamily = (obj) => {
  return async () => {
    let response = await patch(`${apiConst.UPDATE_FONTS}`, obj);
    if (response?.status === 200) {
      return response;
    }
  };
};
export const getWebsiteScreenShotData = (imageUrl) => {
  return async () => {
    const response = await axios.post(
      `${apiConst.BASE_URL_SCREENSHOT}/api/screenshot/?url=${imageUrl}`
    );

    if (response?.data) {
      return response;
    }
  };
};
export const getSubmitWebsiteScreenShotData = (imageUrl) => {
  return async () => {
    const response = await axios.post(
      `${apiConst.BASE_URL_SCREENSHOT}/api/screenshot/${apiConst.PUBLISH_KEY_SCREENSHOT}/?url=${imageUrl}`
    );

    if (response?.data) {
      return response;
    }
  };
};
export const websiteCount = (id) => {
  return async () => {
    const { data } = await patch(`${apiConst.WEBSITE_COUNT(id)}`);
    if (data) {
      return data?.data;
    }
  };
};
export const userPostWebsite = (payload) => {
  return async () => {
    const response = await postWithFileFormData(
      `${apiConst.WEBSITE_POST}`,
      payload
    );

    if (response?.status === 201) {
      return response;
    }
  };
};
export const categoryCarousel = (id, category) => {
  return async (dispatch) => {
    let response;
    const payload = {
      category: category,
    };
    if (TOKEN) {
      response = await post(
        `${apiConst.CATEGORY_DETAILS_WEBSITE(id)}`,
        payload
      );
    } else {
      response = await postWithoutToken(
        `${apiConst.CATEGORY_DETAILS_WEBSITE_WITHOUT_TOKEN(id)}`,
        payload
      );
    }
    if (response) {
      dispatch(categoryCarouselFilter(response?.data?.data));
      return response?.data?.data;
    }
  };
};
export const websiteLike = (isLike, id) => {
  return async () => {
    const response = await post(`${apiConst.LIKE_WEBSITE(isLike)}`, {
      websiteId: id,
    });
    if (response?.status !== 400) {
      if (response) {
        return response;
      }
    }
  };
};
