import React from "react";
import "../../../../styles/saveIcon/saveIcon.scss";
import TooltipComponent from "../Tooltip/TooltipComponent";

const CollectionView = ({
  isDetailsPage,
  obj,
  handleWebsiteCardCollection,
}) => {
  return (
    <React.Fragment>
      <div
        className={isDetailsPage ? "" : "eye-icon-main"}
        onClick={(e) => {
          return handleWebsiteCardCollection(
            e,
            obj?.isCollection,
            obj?.websiteId,
            obj?.screenShotImg,
            obj?.alt
          );
        }}
      >
        <TooltipComponent
          {...{
            tooltipName: "Collection",
          }}
        />
        {obj?.isCollection ? (
          <svg
            width="13"
            height="16"
            viewBox="0 0 13 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 14.6493L6.5 10.5013L1 14.6493V2.20544C1 1.93042 1.14487 1.66666 1.40273 1.47218C1.66059 1.27771 2.01033 1.16846 2.375 1.16846H10.625C10.9897 1.16846 11.3394 1.27771 11.5973 1.47218C11.8551 1.66666 12 1.93042 12 2.20544V14.6493Z"
              fill={isDetailsPage ? "grey" : "white"}
              stroke={isDetailsPage ? "grey" : "white"}
              strokeWidth="1.8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          <svg
            width="13"
            height="16"
            viewBox="0 0 13 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 14.6493L6.5 10.5014L1 14.6493V2.2055C1 1.93048 1.14487 1.66672 1.40273 1.47224C1.66059 1.27777 2.01033 1.16852 2.375 1.16852H10.625C10.9897 1.16852 11.3394 1.27777 11.5973 1.47224C11.8551 1.66672 12 1.93048 12 2.2055V14.6493Z"
              stroke={isDetailsPage ? "grey" : "white"}
              strokeWidth="1.8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
      {/* {isSuccessModal && (
        <SuccessModal
          {...{
            isSuccessModal,
            setIsSuccessModal,
            descriptionText: "Your Collection Added Successfully",
          }}
        />
      )} */}
    </React.Fragment>
  );
};

export default CollectionView;
