import { Tag } from "antd";
import {
  AGENCY_NAME_LENGTH,
  AGENCY_NAME_REQUIRED,
  BIO_REQUIRED,
  BUDGET_REQUIRED,
  CATEGORY_REQUIRED,
  CHARACTER_REGEX,
  COLLECTION_LENGTH,
  COLLECTION_REQUIRED,
  CONFIRM_PASSWORD_REQUIRED,
  COUNTRY_REQUIRED,
  CURRENT_PASSWORD_REQUIRED,
  DESCRIPTION_LENGTH,
  DESCRIPTION_REQUIRED,
  DESIGN_REQUIRED,
  EMAIL_REGEX,
  EMAIL_REQUIRED,
  FN_REQUIRED,
  FONTS_REQUIRED,
  HUNDRED_COUNT,
  LINK_REGEX,
  LN_REQUIRED,
  MAX_LENGTH_CATEGORY,
  MAX_LENGTH_FONTS,
  MAX_LENGTH_STYLE,
  MIN_LENGTH_CATEGORY,
  MIN_LENGTH_FONTS,
  MIN_LENGTH_STYLE,
  NAME_LENGTH,
  ONLY_CHAR,
  PASSWORD_LENGTH,
  PASSWORD_REGEX,
  PASSWORD_REQUIRED,
  PHONE_REQUIRED,
  PORTFOLIO_TITLE_REQUIRED,
  PROJECT_DESCRIPTION_LENGTH,
  PROJECT_DESCRIPTION_REQUIRED,
  STYLE_REQUIRED,
  TIME_REQUIRED,
  USERNAME_LENGTH,
  USERNAME_REQUIRED,
  VALID_EMAIL,
  VALID_PASSWORD,
  VALID_PERSONAL_WEBSITE,
  VALID_WEBSITE,
  WEBSITE_REQUIRED,
  WEBSITE_TITLE_LENGTH,
  WEB_TITLE_REQUIRED,
} from "./primitive";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import {
  confirmPassword,
  confirmPasswordIcon,
  discount,
  faceBookProfile,
  fail,
  figmaProfile,
  gitProfile,
  instagramProfile,
  mail,
  password,
  review,
  success,
  twitterProfile,
  uploadSuccessfully,
  verified,
} from "../../public/assets";

export const VALIDATION = {
  agencyName: {
    required: true,
    pattern: CHARACTER_REGEX,
    maxLength: 30,
    requiredMsg: AGENCY_NAME_REQUIRED,
    validMsg: ONLY_CHAR,
    lengthMsg: AGENCY_NAME_LENGTH,
  },
  websiteUrl: {
    required: true,
    requiredMsg: WEBSITE_REQUIRED,
    pattern: LINK_REGEX,
    validMsg: VALID_WEBSITE,
  },
  ssUrl: {
    required: true,
    requiredMsg: WEBSITE_REQUIRED,
    pattern: LINK_REGEX,
    validMsg: VALID_WEBSITE,
  },
  websiteTitle: {
    required: true,
    requiredMsg: WEB_TITLE_REQUIRED,
    maxLength: 81,
    lengthMsg: WEBSITE_TITLE_LENGTH,
  },
  title: {
    required: true,
    requiredMsg: WEB_TITLE_REQUIRED,
    maxLength: 81,
    lengthMsg: WEBSITE_TITLE_LENGTH,
  },
  description: {
    required: true,
    requiredMsg: DESCRIPTION_REQUIRED,
    maxLength: 201,
    lengthMsg: DESCRIPTION_LENGTH,
  },
  country: {
    required: true,
    requiredMsg: COUNTRY_REQUIRED,
    maxLengthMsg: "",
    minLengthMsg: "",
  },
  category: {
    required: true,
    requiredMsg: CATEGORY_REQUIRED,
    maxLength: 5,
    minLength: 3,
    maxLengthMsg: MAX_LENGTH_CATEGORY,
    minLengthMsg: MIN_LENGTH_CATEGORY,
  },
  style: {
    required: true,
    requiredMsg: STYLE_REQUIRED,
    maxLength: 5,
    minLength: 3,
    maxLengthMsg: MAX_LENGTH_STYLE,
    minLengthMsg: MIN_LENGTH_STYLE,
  },
  fonts: {
    required: true,
    maxLength: 7,
    minLength: 2,
    requiredMsg: FONTS_REQUIRED,
    maxLengthMsg: MAX_LENGTH_FONTS,
    minLengthMsg: MIN_LENGTH_FONTS,
  },
  email: {
    required: true,
    pattern: EMAIL_REGEX,
    maxLength: 500,
    requiredMsg: EMAIL_REQUIRED,
    validMsg: VALID_EMAIL,
  },
  currentPassword: {
    required: true,
    pattern: PASSWORD_REGEX,
    maxLength: 500,
    minLength: 8,
    requiredMsg: CURRENT_PASSWORD_REQUIRED,
    validMsg: VALID_PASSWORD,
    lengthMsg: PASSWORD_LENGTH,
  },
  password: {
    required: true,
    pattern: PASSWORD_REGEX,
    maxLength: 500,
    minLength: 8,
    requiredMsg: PASSWORD_REQUIRED,
    validMsg: VALID_PASSWORD,
    lengthMsg: PASSWORD_LENGTH,
  },
  confirmPassword: {
    required: true,
    maxLength: 500,
    minLength: 8,
    requiredMsg: CONFIRM_PASSWORD_REQUIRED,
  },
  firstName: {
    required: true,
    pattern: CHARACTER_REGEX,
    maxLength: 16,
    requiredMsg: FN_REQUIRED,
    validMsg: ONLY_CHAR,
    lengthMsg: NAME_LENGTH,
  },
  lastName: {
    required: true,
    pattern: CHARACTER_REGEX,
    maxLength: 16,
    requiredMsg: LN_REQUIRED,
    validMsg: ONLY_CHAR,
    lengthMsg: NAME_LENGTH,
  },
  phoneNumber: {
    required: true,
    requiredMsg: PHONE_REQUIRED,
  },
  personalWebsite: {
    pattern: LINK_REGEX,
    validMsg: VALID_PERSONAL_WEBSITE,
  },
  portfolioUrl: {
    pattern: LINK_REGEX,
    validMsg: VALID_PERSONAL_WEBSITE,
  },
  calendlyUrl: {
    pattern: LINK_REGEX,
    validMsg: VALID_PERSONAL_WEBSITE,
  },
  country: {
    required: true,
    requiredMsg: COUNTRY_REQUIRED,
  },
  collectionName: {
    required: true,
    requiredMsg: COLLECTION_REQUIRED,
    maxLength: 25,
    lengthMsg: COLLECTION_LENGTH,
  },
  userName: {
    required: true,
    requiredMsg: USERNAME_REQUIRED,
    maxLength: 30,
    lengthMsg: USERNAME_LENGTH,
  },
  designRequirement: {
    required: true,
    requiredMsg: DESIGN_REQUIRED,
    maxLength: 80,
    lengthMsg: DESIGN_REQUIRED,
  },
  projectDescription: {
    required: true,
    requiredMsg: PROJECT_DESCRIPTION_REQUIRED,
    maxLength: 2500,
    lengthMsg: PROJECT_DESCRIPTION_LENGTH,
  },
  timeRequired: {
    required: true,
    requiredMsg: TIME_REQUIRED,
  },
  budget: {
    required: true,
    requiredMsg: BUDGET_REQUIRED,
  },
  Bio: {
    required: true,
    requiredMsg: BIO_REQUIRED,
  },
  portfolioTitle: {
    required: true,
    requiredMsg: PORTFOLIO_TITLE_REQUIRED,
  },
};
export const GET_IN_TOUCH_RADIO = [
  "budget",
  "timeRequired",
  "designRequirement",
  "projectDescription",
];
export const PORTFOLIO_TIMEREQUERIED = [
  {
    value: "ASAP",
    label: "ASAP",
  },
  {
    value: "Within the next month",
    label: "Within the next month",
  },
  {
    value: "Month",
    label: "Month",
  },
];
export const PORTFOLIO_BUDGET = [
  {
    value: "< 500",
    label: "< 500",
  },
  {
    value: "500 - 1K",
    label: "500 - 1K",
  },
  {
    value: "1K - 1.5K",
    label: "1K - 1.5K",
  },
  {
    value: "1.5K - 2K",
    label: "1.5K - 2K",
  },
  {
    value: "More than 2K",
    label: "More than 2K",
  },
];
export const USER_ACCOUNT_TEXTAREA_FIELDS = [
  {
    name: "portfolioTitle",
    type: "textarea",
    placeholder: "Enter Portfolio Title",
    label: "Portfolio Title",
    maxLength: HUNDRED_COUNT,
    rows: 5,
  },
  {
    name: "Bio",
    type: "textarea",
    placeholder: "Enter Bio",
    label: "Bio",
    maxLength: 1020,
    rows: 5,
  },
];
export const PORTFOLIO_TAB = ["Work", "About"];
export const USER_FILTER_DATA = [
  "All",
  "Approved",
  "Review",
  "Pending",
  "Rejected",
  "Blocked",
];
export const USER_SETTINGS = [
  "General",
  "Account",
  "Security",
  "Social Profiles",
  "Session",
];
export const AD_WEBSITE_FIELD = [
  {
    name: "agencyName",
    type: "text",
    placeholder: "Add Agency Name",
    label: "Agency Name*",
    maxLength: 30,
    className: `${"fade-up-animation"}`,
  },
  {
    name: "websiteUrl",
    type: "text",
    placeholder: "Add Website URL",
    label: "Website URL*",
    maxLength: 7000,
    className: `${"fade-up-animation1"}`,
  },
  {
    name: "websiteTitle",
    type: "text",
    placeholder: "Add Website Title",
    label: "website title*",
    maxLength: 80,
    className: `${"fade-up-animation2 "}`,
  },
  {
    name: "description",
    type: "text",
    placeholder: "Add Description",
    label: "Description*",
    maxLength: 200,
    className: `${"fade-up-animation3"}`,
  },
];
export const VERIFY_ACCOUNT_FIELDS = [
  {
    name: "firstName",
    type: "text",
    placeholder: "Enter First Name",
    label: "First Name",
    maxLength: 16,
  },
  {
    name: "lastName",
    type: "text",
    placeholder: "Enter Last Name",
    label: "Last Name",
    maxLength: 16,
  },
];
export const ONLINE_PRESENCE_FIELDS = [
  {
    name: "personalWebsite",
    type: "url",
    placeholder: "Enter Personal website",
    label: "Personal website",
    maxLength: 2000,
  },
  {
    name: "portfolioUrl",
    type: "url",
    placeholder: "Enter Portfolio URL",
    label: "Portfolio URL",
    maxLength: 2000,
  },
  {
    name: "calendlyUrl",
    type: "url",
    placeholder: "Enter Calendly URL",
    label: "Calendly URL",
    maxLength: 2000,
  },
];
export const USER_EDIT_WEBSITE_FIELD = [
  {
    name: "title",
    type: "text",
    placeholder: "Edit Website Title",
    label: "Website title*",
    maxLength: 80,
  },
  {
    name: "description",
    type: "text",
    placeholder: "Edit Description",
    label: "Description*",
    maxLength: 200,
  },
  {
    name: "agencyName",
    type: "text",
    placeholder: "Edit Agency Name",
    label: "Agency Name*",
    maxLength: 30,
  },
];
export const CHECKBOX_FIELD = [
  {
    name: "category",
    label: "Category*",
    number: "06",
    className: `${"fade-up-animation2 "}`,
  },
  {
    name: "style",
    label: "Style*",
    number: "07",
    className: `${"fade-up-animation3"}`,
  },
];
export const USER_EDIT_WEBSITE_FIELD_NAME = [
  { name: "agencyName" },
  { name: "title" },
  { name: "description" },
  { name: "category" },
  { name: "style" },
];
export const INPUT_FIELD_NAME = [
  { name: "agencyName" },
  { name: "websiteUrl" },
  { name: "websiteTitle" },
  { name: "description" },
  { name: "category" },
  { name: "style" },
  { name: "country" },
  { name: "fonts" },
];
export const AUTHENTICATION_FIELD = [
  {
    name: "email",
    type: "email",
    placeholder: "Enter your email",
    label: "Email address",
    image: mail,
    maxLength: 500,
  },
  {
    name: "password",
    type: "password",
    placeholder: "Enter your password",
    label: "Password",
    image: password,
    maxLength: 500,
    minLength: 8,
  },
];
export const PROFILE_EMPTY_SOCIAL_ICONS = [
  {
    image: twitterProfile,
    socialSiteName: "twitter",
  },
  {
    image: faceBookProfile,
    socialSiteName: "facebook",
  },
  {
    image: instagramProfile,
    socialSiteName: "instagram",
  },
  {
    image: gitProfile,
    socialSiteName: "github",
  },
  {
    image: figmaProfile,
    socialSiteName: "figma",
  },
];
export const PROFILE_EMPTY_SOCIAL_NAME = [
  "twitter",
  "facebook",
  "instagram",
  "github",
  "figma",
];
export const RESET_PASSWORD_FIELD = [
  {
    name: "password",
    type: "password",
    placeholder: "Enter New Password",
    label: "Enter New password",
    image: confirmPasswordIcon,
    maxLength: 500,
    minLength: 8,
  },
  {
    name: "confirmPassword",
    type: "password",
    placeholder: "Enter Confirm Password",
    label: "Enter Confirm Password",
    image: confirmPasswordIcon,
    maxLength: 500,
  },
];
export const FORGOT_PASSWORD_FIELD = [
  {
    name: "password",
    type: "password",
    placeholder: "Enter New Password",
    label: "Enter New password",
    image: password,
    maxLength: 500,
    minLength: 8,
  },
  {
    name: "confirmPassword",
    type: "password",
    placeholder: "Enter Confirm Password",
    label: "Enter confirm password",
    image: confirmPassword,
    maxLength: 500,
  },
];
export const PAYMENT_HISTORY_HEADING = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Number",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
  },
  {
    title: "AD spot ID",
    dataIndex: "adSpotId",
    key: "adSpotId",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Payment ID",
    dataIndex: "paymentId",
    key: "paymentId",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (_, { status }) => (
      <>
        {status?.toLowerCase() === "success"?.toLowerCase() ? (
          <Tag icon={<CheckCircleOutlined />} color="success">
            success
          </Tag>
        ) : (
          <Tag icon={<CloseCircleOutlined />} color="error">
            error
          </Tag>
        )}
      </>
    ),
    fixed: "right",
  },
];
export const DATA = [
  {
    firstName: "John",
    lastName: "Doe",
    email: "JohnDoe@gmail.com",
    phoneNumber: "1236987456",
    adSpotId: "dbba9537-aa93-4293-84ff-6cb7de79db09",
    amount: " USD $69.9",
    date: "Dec 31, 2023",
    paymentId: "3NZXW8SFdFGsXIsd0gdEnrNn",
    status: "Success",
  },
  {
    firstName: "John",
    lastName: "Brown",
    email: "JohnDoe@gmail.com",
    phoneNumber: "1236987456",
    adSpotId: "dbba9537-aa93-4293-84ff-6cb7de79db09",
    amount: " USD $69.9",
    date: "Dec 31, 2023",
    paymentId: "3NZXW8SFdFGsXIsd0gdEnrNn",
    status: "Fail",
  },
];
export const PAYMENT_STATUS = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "Success",
    label: "Success",
  },
  {
    value: "Fail",
    label: "Fail",
  },
];
export const HOME_FILTER = [
  {
    value: "newest",
    label: "Newest",
  },
  {
    value: "oldest",
    label: "Oldest",
  },
  {
    value: "popular",
    label: "Popular",
  },
];
export const USER_PORTFOLIO_FILTER = [
  {
    value: "ASC",
    label: "Newest",
  },
  {
    value: "DESC",
    label: "Oldest",
  },
  {
    value: "popular",
    label: "Popular",
  },
];
export const COLLECTION_DATA = [
  {
    collection: ["https://leksa.landingmuse.com/uploads/hal2nl.png"],
    name: "The Elon Musk Collection",
    count: 1,
  },
  {
    collection: [
      "https://leksa.landingmuse.com/uploads/hal2nl.png",
      "https://leksa.landingmuse.com/uploads/atriumse.png",
    ],
    name: "The Mark Zckerberg Collection",
    count: 2,
  },
  {
    collection: [
      "https://leksa.landingmuse.com/uploads/hal2nl.png",
      "https://leksa.landingmuse.com/uploads/atriumse.png",
      "https://leksa.landingmuse.com/uploads/duffel.png",
    ],
    name: "The Mark Zckerberg Collection",
    count: 3,
  },
  {
    collection: [
      "https://leksa.landingmuse.com/uploads/hal2nl.png",
      "https://leksa.landingmuse.com/uploads/atriumse.png",
      "https://leksa.landingmuse.com/uploads/duffel.png",
      "https://leksa.landingmuse.com/uploads/windrises.png",
    ],
    name: "The Mark Zckerberg Collection",
    count: 4,
  },
];
export const PROMOTION_PLAN = [
  {
    title: "BASIC Plan",
    price: "8$",
    month: "/months",
    billed: "Billed annually you",
    savePrice: "Save 56$",
    discount: "-58%",
    description:
      "Ideal for freelancers, independent professionals and entrepreneurs.",
    btnText: "Select Basic",
  },
  {
    title: "PROFESSIONAL plan",
    recommended: "RECOMMENDED",
    price: "13.8$",
    month: "/months",
    billed: "Billed annually you",
    savePrice: "Save 84 $",
    discount: "-38%",
    description: "The gold standard investment for all agencies and studios.",
    btnText: "Select Professional",
  },
  {
    title: "INTERNATIONAL plan",
    price: "55$",
    month: "/months",
    billed: "Billed annually you",
    savePrice: "Save 188 $",
    discount: "-75%",
    description:
      "The creme de la creme - high impact visibility for bold industry leaders.",
    btnText: "Select International",
  },
];
export const PROMOTE_PRODUCT = [
  {
    title: "Company Showcase",
    description:
      "Add a comprehensive showcase of your work, including projects not submitted, through galleries, videos and photos.",
    image:
      "https://assets.awwwards.com/assets/redesign/images/pages/user-pro/img-2.png",
  },
  {
    title: "Company Showcase",
    description:
      "Add a comprehensive showcase of your work, including projects not submitted, through galleries, videos and photos.",
    image:
      "https://assets.awwwards.com/assets/redesign/images/pages/user-pro/img-2.png",
  },
];
export const ALPHABETS = [
  "Aa",
  "Bb",
  "Cc",
  "Dd",
  "Ee",
  "Ff",
  "Gg",
  "Hh",
  "Ii",
  "Jj",
  "Kk",
  "Ll",
  "Mm",
  "Nn",
  "Oo",
  "Pp",
  "Qq",
  "Ss",
  "Tt",
  "Uu",
  "Vv",
  "Ww",
  "Xx",
  "Yy",
  "Zz",
];
// Notification
export const NOTIFICATION_DATA = {
  website_approved: {
    status: "website_approved",
    description: "Your website has been approved by admin",
    title: "Congratulations! Your website has been approved",
    image: verified,
  },
  website_rejected: {
    status: "website_rejected",
    description: "Oops! your website has been rejected",
    title: "Oops! your website has been rejected",
    image: fail,
  },
  website_review: {
    status: "website_review",
    description: "Your website has been review by admin",
    title: "Congratulations! Your website has been review",
    image: review,
  },
  offer: {
    status: "offer",
    description: "You have new offer",
    image: discount,
    title: "aaa",
  },
  payment_fail: {
    status: "payment_failed",
    description: "Please check your payment failed",
    image: fail,
    title: "aaa",
  },
  payment_success: {
    status: "payment _success",
    description: "Your payment is successful",
    image: success,
    title: "aaa",
  },
};

export const NOTIFICATION_STATUS_KEY = {
  NEW_WEBSITE_UPLOADED: "new_website_uploaded",
  MY_WEBSITE_UPLOADED: "my_website_uploaded",
  WEBSITE_REVIEW: "website_review",
  WEBSITE_APPROVED: "website_approved",
  WEBSITE_REJECTED: "website_rejected",
  WEBSITE_BLOCKED: "website_blocked",
  PAYMENT_SUCCESS: "payment_success",
  PAYMENT_FAILED: "payment_failed",
  NEWS_BROADCAST: "news_broadcast",
  WEBSITE_UPLOADED_SUCCESSFULLY: "website_uploaded_successfully",
  ACCOUNT_VERIFIED_SUCCESSFULLY: "account_verified_successfully",
  ACCOUNT_NOT_VERIFIED_SUCCESSFULLY: "account_not_verified_successfully",
  LIKED_POST: "like_post",
  DISLIKED_POST: "dislike_post",
  DISCOUNT: "discount",
  OFFER: "offer",
};

export const SOCKET_EVENT = {
  JOIN_NOTIFICATION: "join_notification",
  WEBSITE_UPLOADED_SUCCESSFULLY: "website_uploaded_successfully",
  WEBSITE_STATUS_UPDATED: "website_status_updated",
  CONNECTION_ERROR: "connect_error",
  DISCONNECT: "disconnect",
  CONNECTION: "connection",
};

export function getNotificationData(payload) {
  const notificationMap = {
    [NOTIFICATION_STATUS_KEY?.WEBSITE_APPROVED]: {
      description: (
        <div>
          Hurry! Now your{" "}
          <a
            href={payload?.websiteURL}
            target={"_blank"}
            style={{
              textDecoration: "underline",
              color: "#8c9097",
              wordBreak: "break-all",
            }}
          >
            {payload?.websiteURL?.toLowerCase()}
          </a>{" "}
          is visible on our dashboard
        </div>
      ),
      title: "Website Approved",
      image: verified,
    },
    [NOTIFICATION_STATUS_KEY?.NEW_WEBSITE_UPLOADED]: {
      description: (
        <div>
          Hurry! Now your{" "}
          <a
            href={payload?.websiteURL}
            target={"_blank"}
            style={{ textDecoration: "underline", color: "#8c9097" }}
          >
            {payload?.websiteURL?.toLowerCase()}
          </a>{" "}
          is visible on our dashboard
        </div>
      ),
      title: "New Website Uploaded",
      image: verified,
    },
    [NOTIFICATION_STATUS_KEY?.WEBSITE_REJECTED]: {
      description: (
        <div>
          As per our experts results your{" "}
          <a
            href={payload?.websiteURL}
            target={"_blank"}
            style={{ textDecoration: "underline", color: "#8c9097" }}
          >
            {payload?.websiteURL?.toLowerCase()}
          </a>{" "}
          has been rejected. Try editing your website again
        </div>
      ),
      title: "Website Rejected",
      image: fail,
    },
    [NOTIFICATION_STATUS_KEY?.WEBSITE_REVIEW]: {
      description: (
        <div>
          This is to inform you that{" "}
          <a
            href={payload?.websiteURL}
            target={"_blank"}
            style={{ textDecoration: "underline", color: "#8c9097" }}
          >
            {payload?.websiteURL?.toLowerCase()}
          </a>{" "}
          is being reviewed by our experts, a notification will be received for
          further information
        </div>
      ),
      title: "Website Review",
      image: review,
    },
    [NOTIFICATION_STATUS_KEY?.WEBSITE_BLOCKED]: {
      description: (
        <div>
          Your website{" "}
          <a
            href={payload?.websiteURL}
            target={"_blank"}
            style={{ textDecoration: "underline", color: "#8c9097" }}
          >
            {payload?.websiteURL?.toLowerCase()}
          </a>{" "}
          status has been changed by our expert
        </div>
      ),
      title: "Website Blocked",
      image: verified,
    },
    [NOTIFICATION_STATUS_KEY?.OFFER]: {
      status: "offer",
      description: `You have a new offer`,
      image: discount,
      title: "aaa",
    },
    [NOTIFICATION_STATUS_KEY?.PAYMENT_FAILED]: {
      status: "payment_failed",
      description: "Please check your payment failed",
      image: fail,
      title: "aaa",
    },
    [NOTIFICATION_STATUS_KEY?.PAYMENT_SUCCESS]: {
      status: "payment_success",
      description: "Your payment is successful",
      image: success,
      title: "aaa",
    },
    [NOTIFICATION_STATUS_KEY?.MY_WEBSITE_UPLOADED]: {
      description: (
        <div>
          Your website{" "}
          <a
            href={payload?.websiteURL}
            target={"_blank"}
            style={{ textDecoration: "underline", color: "#8c9097" }}
          >
            {payload?.websiteURL?.toLowerCase()}
          </a>{" "}
          status has been changed by our expert
        </div>
      ),
      title: "Your Website Uploaded",
      image: uploadSuccessfully,
    },
  };

  return notificationMap[payload?.status] || undefined;
}
