import Image from "next/image";
import React from "react";

const ImageComponent = ({
  imageSrc,
  imageAlt,
  imageHeight,
  imageWidth,
  imageClassName,
  imageFill,
  imageSizes,
  handleClick,
  imageStyle,
}) => {
  return (
    <Image
      src={imageSrc}
      alt={imageAlt}
      className={imageClassName}
      width={imageWidth}
      height={imageHeight}
      fill={imageFill}
      sizes={imageSizes}
      quality={100}
      onClick={handleClick}
      style={imageStyle}
      priority={true}
    />
  );
};

export default ImageComponent;
