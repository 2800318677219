import React from "react";
import "../../../../../styles/card/card.scss";
import { CollectionContainer, ImageComponent, ViewCountComponent } from "../..";
import { Divider, Popover } from "antd";
import Image from "next/image";
import { filterGetColor } from "@/src/utils";
import { deleteWebIcon, editWebIcon, menu } from "../../../../../public/assets";

const DetailsDrawerOpen = ({
  handleDrawerOpen,
  isMyWebsite,
  handleUserWebsiteDelete,
  handleUserWebsiteEdit,
  handleSaveCollection,
  handleAddCollectionName,
  websiteDataObj,
  token,
  data,
  drawer,
  modal,
  setModal,
  setWebsiteCardData,
  setDrawer,
  manageCollection,
  collectionError,
  handleWebsiteViewCount,
}) => {
  return (
    <div
      className={"overlay"}
      onClick={(e) =>
        handleDrawerOpen(
          websiteDataObj?.websiteId,
          e,
          websiteDataObj?.category?.split(",")
        )
      }
    >
      {isMyWebsite && (
        <div className={"post-overlay"}>
          {websiteDataObj?.status === "pending" ? (
            <p className={"website-pending-text"}>
              Your website is currently in pending process. Our team is
              diligently reviewing it to precisely. We appreciate your patience
              and will notify you promptly in 4 - 5 business working days.
            </p>
          ) : websiteDataObj?.status === "review" ? (
            <p className={"website-review-text"}>
              We're in the process of reviewing your website to ensure it meets
              our standards. Thanks for your patience – we'll notify you once
              the review is complete!
            </p>
          ) : websiteDataObj?.status === "blocked" ? (
            <p className={"website-blocked-text"}>
              Regrettably, access to the website has been blocked due to
              violation of our terms and conditions. We are actively addressing
              the issue and will notify you after resolving.
            </p>
          ) : (
            <React.Fragment>
              {websiteDataObj?.status === "rejected" && (
                <p className={"website-rejected-text"}>
                  Unfortunately, your website has been rejected by our team.
                  We've outlined the significant changes that need to be
                  addressed, and we'll provide further updates shortly.
                </p>
              )}
              <div className={"wrap-menu"}>
                <div className={"main-menu"}>
                  <Popover
                    overlayClassName={"menu-popover"}
                    content={
                      <div className={"popover-menus"}>
                        <div
                          className="popover-menus-wrap"
                          onClick={(e) =>
                            handleUserWebsiteEdit(e, websiteDataObj?.websiteId)
                          }
                        >
                          <ImageComponent
                            imageSrc={editWebIcon}
                            imageAlt="edit icon"
                            imageClassName={"popover-icons"}
                            imageWidth={23}
                            imageHeight={23}
                          />
                          <p>Edit</p>
                        </div>
                        <Divider className={"divider-main"} />
                        <div
                          className="popover-menus-wrap"
                          onClick={(e) =>
                            handleUserWebsiteDelete(
                              e,
                              websiteDataObj?.websiteId,
                              websiteDataObj?.websiteUrl
                            )
                          }
                        >
                          <ImageComponent
                            imageSrc={deleteWebIcon}
                            imageAlt="delete icon"
                            imageClassName={"popover-icons"}
                            imageWidth={23}
                            imageHeight={23}
                          />
                          <p>Delete Website</p>
                        </div>
                      </div>
                    }
                    trigger="click"
                    placement="rightTop"
                    arrow={false}
                    className={"popover-menu-main"}
                    zIndex={900}
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className={"popover-menu"}
                    >
                      <Image src={menu} alt="menu" className={"menu-icon"} />
                    </div>
                  </Popover>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      )}
      {!isMyWebsite && (
        <React.Fragment>
          <div className={"website-colors-main"}>
            <p className={"website-category-image-name"}>
              {websiteDataObj?.category}
            </p>
            {filterGetColor(websiteDataObj?.color)
              ?.slice(0, 5)
              ?.map((websiteDataObj) => (
                <p
                  key={websiteDataObj}
                  className={"website-colors"}
                  style={{
                    backgroundColor: websiteDataObj,
                  }}
                ></p>
              ))}
          </div>
          <ViewCountComponent {...{ handleWebsiteViewCount, websiteDataObj }} />
          <CollectionContainer
            {...{
              token,
              obj: websiteDataObj,
              drawer,
              data,
              manageCollection,
              setWebsiteCardData,
              setDrawer,
              modal,
              collectionError,
              setModal,
              handleSaveCollection,
              handleAddCollectionName,
            }}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default DetailsDrawerOpen;
