export const NEAREST_COLOR = {
  Red: "#FF0000",
  Green: "#008000",
  Blue: "#0000FF",
  Yellow: "#FFFF00",
  Orange: "#FFA500",
  Purple: "#800080",
  Pink: "#FFC0CB",
  Brown: "#A52A2A",
  Black: "#000000",
  White: "#FFFFFF",
  Gray: "#808080",
  Silver: "#C0C0C0",
  Gold: "#FFD700",
  Cyan: "#00FFFF",
  Magenta: "#FF00FF",
  Maroon: "#800000",
  Teal: "#008080",
  Olive: "#808000",
  Lime: "#00FF00",
  Indigo: "#4B0082",
  Coral: "#FF6F61",
  Salmon: "#FA8072",
  Turquoise: "#40E0D0",
  Beige: "#F5F5DC",
  Peach: "#FFDAB9",
  Tan: "#D2B48C",
  Slate: "#708090",
  Khaki: "#F0E68C",
  ForestGreen: "#228B22",
  MidnightBlue: "#191970",
  DarkSlateGray: "#2F4F4F",
  CornflowerBlue: "#6495ED",
  DarkGoldenrod: "#B8860B",
  OliveDrab: "#6B8E23",
  MediumAquamarine: "#66CDAA",
  MediumSlateBlue: "#7B68EE",
  Tomato: "#FF6347",
  DarkOliveGreen: "#556B2F",
  DodgerBlue: "#1E90FF",
};