"use client";
import React, { useRef, useState } from "react";
import LikeView from "./LikeView";
import {
  PortfolioLikeUpdate,
  categoryCarouselUpdate,
  collectionLikeUpdate,
  likeDataFilterUpdate,
  likeDataUpdate,
  likeDataUpdatePaidCard,
} from "@/src/redux/reducers/slices";
import { useDispatch, useSelector } from "react-redux";
import { useNav } from "../context/NavbarProvider";
import { websiteLike } from "@/src/redux/actions";

const LikeContainer = ({ token, obj, isDetailsPage, drawer, setDrawer }) => {
  const [isLikeCelebration, setIsLikeCelebration] = useState({
    id: "",
    isCelebration: false,
  });
  const { setIsAuthModal } = useNav();
  const { profileData } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const audioRef = useRef(null);

  const handleScrollTop = () => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  };
  const handleWebsiteCardLike = async (like, count, id, status, webObj) => {
    if (token) {
      if (profileData?.auth?.isVerified) {
        if (status === "approved") {
          let isLike = !like;
          if (isLike) {
            if (audioRef.current) {
              audioRef.current.play();
            }
            setIsLikeCelebration({
              id: id,
              isCelebration: true,
            });
          }
          let updatedData = {
            ...webObj,
            id,
            isLike: isLike,
            likeCount: isLike ? count + 1 : count === 0 ? 0 : count - 1,
          };
          const updatedScreenshotDetails = {
            ...obj,
            isLike: updatedData?.isLike,
            likeCount: updatedData?.likeCount,
          };

          setDrawer({
            ...drawer,
            data: updatedScreenshotDetails,
          });
          await dispatch(PortfolioLikeUpdate(updatedData));
          await dispatch(collectionLikeUpdate(updatedData));
          await dispatch(likeDataUpdate(updatedData));
          await dispatch(likeDataUpdatePaidCard(updatedData));
          await dispatch(likeDataFilterUpdate(updatedScreenshotDetails));
          await dispatch(categoryCarouselUpdate(updatedData));
          setTimeout(() => {
            setIsLikeCelebration({
              id: id,
              isCelebration: false,
            });
          }, 2000);
          await dispatch(websiteLike(updatedData?.isLike, updatedData?.id));
        }
      } else {
        handleScrollTop();
      }
    } else {
      setIsAuthModal(true);
    }
  };
  return (
    <LikeView
      {...{
        isLikeCelebration,
        handleWebsiteCardLike,
        obj,
        audioRef,
        isDetailsPage,
      }}
    />
  );
};

export default LikeContainer;
