"use client";
import React from "react";
import Link from "next/link";
import Image from "next/image";
import { backArrow } from "../../../../public/assets";
import "../../../../styles/backButton/back.scss";
import { ButtonComponent } from "..";

const BackButtonComponent = ({ classNames, url }) => {
  return (
    <Link href={url} className={`back-main  ${classNames}`}>
      <Image src={backArrow} alt="arrow" className={"back-arrow-icon"} />
      <ButtonComponent classNames={"back-btn"} btnText="back" />
    </Link>
  );
};

export default BackButtonComponent;
